<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<ng-container *ngIf="UserModuleAccess=='Full'">
	<div class="card">
		<div class="card-header bg-primary">
			{{pagetitle}}
		</div>
		<div class="card-body">
			<mat-accordion [hideToggle]="false">
				<mat-expansion-panel (opened)="accordionOpened('RmaBillingListReports')" [expanded]="false">
					<mat-expansion-panel-header class="bg-acc_style_1">
						<mat-panel-title>
							<b>{{RmaBillingListReportsHeader}}</b>
						</mat-panel-title>
						<mat-panel-description>
							<button class="btn btn-sm btn-light" matTooltip="Excel" [matTooltipPosition]="'below'"
								(click)="getRmaBillingListReportsExcel($event)">
								<fa-icon *ngIf="!RmaBillingListReportsExcelLoader"
									[icon]="['fas', 'cloud-download-alt']"></fa-icon>
								<fa-icon *ngIf="RmaBillingListReportsExcelLoader" [icon]="['fas', 'spinner']"
									animation="spin"></fa-icon>
							</button>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div class="row">
						<div class="col-sm-12">
							<date-filter (changed)="dateChanged($event)"></date-filter>
							<div class="row">
								<div class="col-sm-12">
									<div class="table-responsive" style="height: 450px;overflow-y: auto;">
										<table class="myTable table table-bordered table-hover mt-2"
											style="width: 100%;">
											<thead>
												<tr>
													<th style="width: 20% !important;">Company</th>
													<th style="width: 20% !important;">Invoice No.</th>
													<th style="width: 10% !important;">Invoice Date</th>
													<th style="width: 10% !important;">Vessel Count</th>
													<th style="width: 10% !important;">Amount</th>
													<th style="width: 20% !important;">Currency</th>
													<th style="width: 10% !important;">Due Date</th>
												</tr>
											</thead>
											<tbody *ngIf="RmaBillingListReports?.length != 0">
												<tr class="table_td_style"
													*ngFor="let a of RmaBillingListReports;let i = index;">
													<td>{{a.company}}</td>
													<td>{{a.invoice_no}}</td>
													<td>{{a.invoice_date}}</td>
													<td>{{a.vessel_count}}</td>
													<td>{{a.amount}}</td>
													<td>{{a.currency}}</td>
													<td>{{a.due_date}}</td>
												</tr>
											</tbody>
										</table>
										<p *ngIf="RmaBillingListReports?.length == 0 && !RmaBillingListReportsLoader"
											class="text-center">
											No data found!
										</p>
										<p *ngIf="RmaBillingListReportsLoader" class="text-center text-bold">
											<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
											Loading...
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</div>
	<div class="card mt-2">
		<div class="card-header bg-info">
			RMA
		</div>
		<div class="card-body">
			<div class="row border-bottom mb-2">
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
					<mat-form-field>
						<mat-label>Start</mat-label>
						<input matInput [matDatepicker]="start_date_picker_1" [(ngModel)]="cardsFilter.start_date"
							(dateChange)="getCompanyWiseData();">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="start_date_picker_1"></mat-datepicker-toggle>
						<mat-datepicker #start_date_picker_1></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
					<mat-form-field>
						<mat-label>End</mat-label>
						<input matInput [matDatepicker]="end_date_picker_1" [(ngModel)]="cardsFilter.end_date"
							(dateChange)="getCompanyWiseData();">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="end_date_picker_1"></mat-datepicker-toggle>
						<mat-datepicker #end_date_picker_1></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 mt-1">
					<ng-select appendTo="body" [items]="companyList_dropdown" bindLabel="comp_name" bindValue="hc_id"
						[clearable]="true" [multiple]="false" [(ngModel)]="cardsFilter.company_id" placeholder="Company"
						(change)="rma_dashboard_fleet_dropdown(2);getCompanyWiseData();">
					</ng-select>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 mt-1">
					<ng-select appendTo="body" [items]="fleetList_dropdown2" bindLabel="fleet_name" bindValue="hc_id"
						[clearable]="true" [multiple]="false" [(ngModel)]="cardsFilter.fleet_id" placeholder="Fleet"
						(change)="getCompanyWiseData();">
					</ng-select>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
					<ng-select appendTo="body" [items]="ranks_dropdown" bindLabel="rank_name" bindValue="rank_name"
						[clearable]="true" [multiple]="false" [(ngModel)]="cardsFilter.rank" placeholder="Rank"
						(change)="getCompanyWiseData()">
					</ng-select>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
					<ng-select appendTo="body" [items]="symptoms_dropdown" bindLabel="group_name" [clearable]="true"
						[multiple]="false" [(ngModel)]="cardsFilter.symptom" placeholder="Symptom"
						(change)="rma_dashboard_sub_symptoms_bar_chart();">
					</ng-select>
				</div>
			</div>
			<!-- <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 mt-1">
				<ng-select appendTo="body" [items]="yearList" [clearable]="false" [multiple]="false"
					[(ngModel)]="cardsFilter.year" placeholder="Year"
					(change)="rma_dashboard_sub_symptoms_bar_chart();">
				</ng-select>
			</div> -->
			<div class="row">
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 border-right">
					<div class="row">
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1 p-1" [@enterAnimation]>
							<div class="card1 card-shadow bg-success">
								<div class="text-center">
									<h3 class="mb-0">
										<ng-container
											*ngIf="!company_period_wise_count_loader">{{company_period_wise_count.total_cases}}</ng-container>
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"
											*ngIf="company_period_wise_count_loader"></fa-icon>
									</h3>
									<h4 class="mb-0">Total Cases</h4>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1 p-1" [@enterAnimation]>
							<div class="card1 card-shadow bg-warning">
								<div class="text-center">
									<h3 class="mb-0">
										<ng-container
											*ngIf="!company_period_wise_count_loader">{{company_period_wise_count.total_open_cases}}</ng-container>
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"
											*ngIf="company_period_wise_count_loader"></fa-icon>
									</h3>
									<h4 class="mb-0">Open Cases</h4>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1 p-1" [@enterAnimation]>
							<div class="card1 card-shadow bg-primary">
								<div class="text-center">
									<h3 class="mb-0">
										<ng-container
											*ngIf="!company_period_wise_count_loader">{{company_period_wise_count.med_sign_off_count}}</ng-container>
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"
											*ngIf="company_period_wise_count_loader"></fa-icon>
									</h3>
									<h4 class="mb-0">Medical Sign-off</h4>
									<!-- <h4 class="mb-0">(This Year)</h4> -->
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1 p-1" [@enterAnimation]>
							<div class="card1 card-shadow bg-info">
								<div class="text-center">
									<h3 class="mb-0">
										<ng-container
											*ngIf="!company_period_wise_count_loader">{{company_period_wise_count.avoid_med_sign_off_count}}</ng-container>
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"
											*ngIf="company_period_wise_count_loader"></fa-icon>
									</h3>
									<h4 class="mb-0">Medical Sign-off Avoided</h4>
									<!-- <h4 class="mb-0">(This Year)</h4> -->
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1 p-1" [@enterAnimation]>
							<div class="card1 card-shadow bg-danger">
								<div class="text-center">
									<h3 class="mb-0">
										<ng-container
											*ngIf="!company_period_wise_count_loader">{{company_period_wise_count.med_evacuation_count}}</ng-container>
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"
											*ngIf="company_period_wise_count_loader"></fa-icon>
									</h3>
									<h4 class="mb-0">Diversions/Medical Evac</h4>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1 p-1" [@enterAnimation]>
							<div class="card1 card-shadow bg-secondary">
								<div class="text-center">
									<h3 class="mb-0">
										<ng-container
											*ngIf="!company_period_wise_count_loader">{{company_period_wise_count.avoid_med_evacuation_count}}</ng-container>
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"
											*ngIf="company_period_wise_count_loader"></fa-icon>
									</h3>
									<h4 class="mb-0">Diversions/Medical Evac Avoided</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 border-right">
					<ng-container *ngIf="symptoms_chart_loader">
						<div class="row">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<h1 class="text-center">
									<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									Loading...
								</h1>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="symptoms_chart_data.length && !symptoms_chart_loader">
						<div class="row">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
								<h3 class="text-center">Case Symptoms</h3>
								<div id="symptoms_pie_chart_hc">
									<!-- <ngx-charts-pie-chart [results]="symptoms_chart_data" [gradient]="false"
										[legend]="false" [legendPosition]="pieChartLegendPosition" [legendTitle]="''"
										[labels]="false" [doughnut]="true" [arcWidth]="arc_width"
										[customColors]="symptomPieChartColors">
										<ng-template #tooltipTemplate let-model="model">
											<h4 class="my-0">{{model.name}}</h4>
											<h5 class="my-0">{{model.value}}%</h5>
										</ng-template>
									</ngx-charts-pie-chart> -->
									<!-- [view]="[getHeightWidthForChart('symptoms_pie_chart_hc').width,getHeightWidthForChart('symptoms_pie_chart_hc').height]" -->
									<ngx-charts-gauge [results]="symptoms_chart_data" [legend]="false"
										[legendPosition]="pieChartLegendPosition" [legendTitle]="''"
										[customColors]="symptomPieChartColors" [min]="0" [max]="100"
										[valueFormatting]="gaugeChartFormat" [showText]="false">
									</ngx-charts-gauge>
								</div>
								<div>
									<div *ngFor="let item of symptomPieChartColors" class="legend-item">
										<div class="legend-color-box" [ngStyle]="{'background-color':item.value}">&nbsp;
										</div>
										{{item.name}}
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<ng-container *ngIf="sub_symptoms_chart_loader">
						<div class="row">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
								<h1 class="text-center">
									<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									Loading...
								</h1>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="sub_symptoms_chart_data && !sub_symptoms_chart_loader">
						<div class="row">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
								<h3 class="text-center">Case Sub-symptoms</h3>
								<div id="sub_symptoms_bar_chart_hc">
									<ngx-charts-bar-vertical-stacked [results]="sub_symptoms_chart_data"
										[gradient]="false" [xAxis]="true" [yAxis]="true" [legend]="false"
										[legendPosition]="pieChartLegendPosition" [legendTitle]="''"
										[showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="'Month'"
										[yAxisLabel]="'Count'" [animations]="true"
										[customColors]="subSymptomBarChartColors">
									</ngx-charts-bar-vertical-stacked>
									<!-- [view]="[getHeightWidthForChart('sub_symptoms_bar_chart_hc').width,getHeightWidthForChart('sub_symptoms_bar_chart_hc').height]" -->
								</div>
								<div>
									<div *ngFor="let item of subSymptomBarChartColors" class="legend-item">
										<div class="legend-color-box" [ngStyle]="{'background-color':item.value}">&nbsp;
										</div>
										{{item.name}}
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<div class="card mt-2">
		<div class="card-header bg-success">
			Make your own analysis
		</div>
		<div class="card-body">
			<div class="row border-bottom mb-2">
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
					<mat-form-field>
						<mat-label>Start</mat-label>
						<input matInput [matDatepicker]="start_date_picker_2" [(ngModel)]="ownAnalysisFilter.start_date"
							(dateChange)="getOwnAnalysisAll();">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="start_date_picker_2"></mat-datepicker-toggle>
						<mat-datepicker #start_date_picker_2></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
					<mat-form-field>
						<mat-label>End</mat-label>
						<input matInput [matDatepicker]="end_date_picker_2" [(ngModel)]="ownAnalysisFilter.end_date"
							(dateChange)="getOwnAnalysisAll();">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="end_date_picker_2"></mat-datepicker-toggle>
						<mat-datepicker #end_date_picker_2></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 mt-1">
					<ng-select appendTo="body" [items]="companyList_dropdown" bindLabel="comp_name" bindValue="hc_id"
						[clearable]="true" [multiple]="false" [(ngModel)]="ownAnalysisFilter.company_id"
						placeholder="Company" (change)="rma_dashboard_fleet_dropdown(1);getOwnAnalysisAll();">
					</ng-select>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 mt-1">
					<ng-select appendTo="body" [items]="fleetList_dropdown1" bindLabel="fleet_name" bindValue="hc_id"
						[clearable]="true" [multiple]="false" [(ngModel)]="ownAnalysisFilter.fleet_id"
						placeholder="Fleet" (change)="getOwnAnalysisAll();">
					</ng-select>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
					<ng-select appendTo="body" [items]="symptoms_dropdown" bindLabel="group_name" [clearable]="true"
						[multiple]="false" [(ngModel)]="ownAnalysisFilter.symptom" placeholder="Symptom"
						(change)="rma_dashboard_sub_symptoms_dropdown(1);getOwnAnalysisAll();">
					</ng-select>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
					<ng-select appendTo="body" [items]="subSymptoms_dropdown1" bindLabel="group_name" [clearable]="true"
						[multiple]="false" [(ngModel)]="ownAnalysisFilter.sub_symptom" placeholder="Sub-symptom"
						(change)="getOwnAnalysisAll();">
					</ng-select>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 border-right">
					<div class="row">
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<ng-select appendTo="body" [items]="nationalities_dropdown" bindLabel="nationality" bindValue="hc_id"
								[clearable]="true" [multiple]="false" [(ngModel)]="ownAnalysisFilter.nationality"
								placeholder="Nationality" (change)="rma_dashboard_own_analysis()">
							</ng-select>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<ng-select appendTo="body" [items]="ranks_dropdown" bindLabel="rank_name" bindValue="rank_name"
								[clearable]="true" [multiple]="false" [(ngModel)]="ownAnalysisFilter.rank"
								placeholder="Rank" (change)="rma_dashboard_own_analysis()">
							</ng-select>
						</div>
						<!-- <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-1">
							<ng-select appendTo="body" [items]="ageList" bindLabel="age" bindValue="id"
								[clearable]="true" [multiple]="false" [(ngModel)]="ownAnalysisFilter.age"
								placeholder="Age" (change)="rma_dashboard_own_analysis();">
							</ng-select>
						</div> -->
					</div>
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div class="doughnut doughnut-shadow" *ngIf="ownAnalysisFilter.company_id">
								<span
									*ngIf="!rma_dashboard_own_analysis_loader">{{rma_dashboard_own_analysis_data.total_inner_count_percent}}%</span>
								<fa-icon *ngIf="rma_dashboard_own_analysis_loader" [icon]="['fas', 'spinner']"
									animation="spin"></fa-icon>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 border-right">
					<div class="row">
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<ng-select appendTo="body" [items]="case_statusList" [clearable]="true" [multiple]="false"
								[(ngModel)]="ownAnalysisFilter.case_status" placeholder="Case Status"
								(change)="rma_dashboard_company_analysis()">
							</ng-select>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<p *ngIf="rma_dashboard_company_analysis_loader" class="text-center text-bold">
								<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
								Loading...
							</p>
							<ngx-charts-bar-vertical *ngIf="!rma_dashboard_company_analysis_loader && ownAnalysisFilter.company_id"
								[results]="rma_dashboard_company_analysis_data" [gradient]="false" [xAxis]="true"
								[yAxis]="true" [legend]="false" [showXAxisLabel]="true" [showYAxisLabel]="true"
								[xAxisLabel]="''" [yAxisLabel]="'Count'" [animations]="true" [scheme]="'vivid'">
							</ngx-charts-bar-vertical>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<ng-container *ngIf="case_closure_pie_chart_loader">
						<div class="row">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<h1 class="text-center">
									<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									Loading...
								</h1>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="case_closure_pie_chart_data.length && !case_closure_pie_chart_loader">
						<div class="row">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
								<h3 class="text-center">Case Closure</h3>
								<div id="case_closure_pie_chart_hc">
									<ngx-charts-pie-chart [results]="case_closure_pie_chart_data" [gradient]="false"
										[legend]="false" [legendPosition]="pieChartLegendPosition" [legendTitle]="''"
										[labels]="false" [doughnut]="true" [arcWidth]="arc_width"
										[customColors]="case_closure_pie_chart_colors">
										<ng-template #tooltipTemplate let-model="model">
											<h4 class="my-0">{{model.name}}</h4>
											<h5 class="my-0">{{model.value}}%</h5>
										</ng-template>
									</ngx-charts-pie-chart>
								</div>
								<div>
									<div *ngFor="let item of case_closure_pie_chart_colors" class="legend-item">
										<div class="legend-color-box" [ngStyle]="{'background-color':item.value}">&nbsp;
										</div>
										{{item.name}}
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>