import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { emailPattern } from '../shared/constants';

@Component({
	selector: 'app-reset-password-new',
	templateUrl: './reset-password-new.component.html',
	styleUrl: './reset-password-new.component.css'
})
export class ResetPasswordNewComponent {
	emailForm!: FormGroup;
	otpForm!: FormGroup;
	recoverForm!: FormGroup;
	year: number = new Date().getFullYear();
	ScreenHeight!: number;
	loadershow: boolean = false;
	resetPasswordError = null;
	step = 1;

	constructor(private apiService: ApiService, private router: Router, private formBuilder: FormBuilder, private activeRoute: ActivatedRoute, private toastr: ToastrService) { }

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.emailForm = this.formBuilder.group({
				email: [null, [Validators.required, Validators.pattern(emailPattern)]],
			});
			this.otpForm = this.formBuilder.group({
				otp: [null, [Validators.required, Validators.pattern(/^[0-9]{4,4}$/g)]]
			});
			this.recoverForm = this.formBuilder.group({
				password: [null, [Validators.required, this.passwordMinLengthValidator(8), this.passwordCapitalValidator(), this.passwordNumericValidator()]],
				confirmPassword: [null, [Validators.required]]
			}, {
				validators: this.passwordMatchValidator.bind(this)
			});
			setTimeout(() => {
				this.ScreenHeight = (window.innerHeight);
			}, 0);
		});
	}

	get f1() { return this.emailForm.controls; }

	get f2() { return this.otpForm.controls; }

	get f3() { return this.recoverForm.controls; }

	submitEmail() {
		if (this.emailForm.invalid) {
			return;
		}
		let params = {
			email: this.f1.email.value
		}
		/* this.loadershow = true;
		this.apiService.sendOTP(params).subscribe((data:any) => {
			this.loadershow = false;
			this.toastr.success("OTP sent to your email id", 'Success',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.step = 2;
		},(error:any)=>{
			this.loadershow = false;
			this.resetPasswordError=error["error"];
			setTimeout(() => {
				this.resetPasswordError=null;
			}, 10000);
		}); */
	}

	submitOTP() {
		if (this.otpForm.invalid) {
			return;
		}
		let params = {
			email: this.f1.email.value,
			otp: parseInt(this.f2.otp.value)
		}
		/* this.loadershow = true;
		this.apiService.verifyOTP(params).subscribe((data:any) => {
			this.loadershow = false;
			this.toastr.success("OTP verified successfully", 'Success',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.step = 3;
		},(error:any)=>{
			this.loadershow = false;
			this.resetPasswordError=error["error"];
			setTimeout(() => {
				this.resetPasswordError=null;
			}, 10000);
		}); */
	}

	passwordMatchValidator(formGroup: FormGroup) {
		const password = formGroup.get('password')?.value;
		const confirmPassword = formGroup.get('confirmPassword')?.value;
		return password === confirmPassword ? null : { passwordNotMatch: true };
	}

	passwordMinLengthValidator(min: number): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			let password = control.value;
			if (password == null) {
				return null;
			}
			if (password.length >= min) {
				return null;
			} else {
				return { minlengtherror: true };
			}
		};
	}

	passwordCapitalValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			let password = control.value;
			if (password == null) {
				return null;
			}
			if (password.replace(/[^A-Z]/g, "").length >= 1) {
				return null;
			} else {
				return { capitalerror: true };
			}
		};
	}

	passwordNumericValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			let password = control.value;
			if (password == null) {
				return null;
			}
			if (password.replace(/[^0-9]/g, "").length >= 1) {
				return null;
			} else {
				return { numericerror: true };
			}
		};
	}

	submitForm() {
		if (this.recoverForm.invalid) {
			return;
		}
		let params = {
			email: this.f1.email.value,
			password: this.f3.password.value
		}
		this.loadershow = true;
		/* this.apiService.newPassword(params).subscribe((data:any) => {
			this.loadershow = false;
			this.toastr.success("Your password is changed successfully.", 'Success',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.step = 4;
			setTimeout(() => {
				this.apiService.navigateToLogin(false);
			}, 3000);
		},(error:any)=>{
			this.loadershow = false;
			this.resetPasswordError=error["error"];
			setTimeout(() => {
				this.resetPasswordError=null;
			}, 10000);
		}); */
	}
}
