<!-- <div class="card mb-1 px-1"> -->
<nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb" class="mb-1 px-1">
	<ol class="breadcrumb">
		<li class="breadcrumb-item" *ngFor="let item of breadcrumbList; let i = index"
			[class.active]="i===breadcrumbList.length-1">
			<a [routerLink]="item.path" *ngIf="i!==breadcrumbList.length-1">{{ item.name }}</a>
			<span *ngIf="i===breadcrumbList.length-1"><b>{{ item.name }}</b></span>
		</li>
	</ol>
</nav>
<!-- </div> -->