import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams ,HttpHeaders} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	constructor(private httpClient: HttpClient, private router: Router) { }

	private API_URL = environment.API_URL;

	private sessionExpMsg = new Subject<any>();
	sendSessionExpMessage() {
		this.sessionExpMsg.next(null);
	}
	getSessionExpMessage(): Observable<any> {
		return this.sessionExpMsg.asObservable();
	}

	getDefaultDateRange(returnString: boolean) {
		let today = new Date();
		let yesterday = new Date();
		yesterday.setDate(yesterday.getDate() - 1);
		return {
			start_date: returnString ? this.getDateStringFromDate(yesterday) : yesterday,
			end_date: returnString ? this.getDateStringFromDate(today) : today
		}
	}

	getDefaultDateRange3Months(returnString: boolean) {
		let today = new Date();
		let fromDate = new Date();
		fromDate.setDate(fromDate.getDate() - 90);
		return {
			start_date: returnString ? this.getDateStringFromDate(fromDate) : fromDate,
			end_date: returnString ? this.getDateStringFromDate(today) : today
		}
	}

	getDateStringFromDate(date: any) {
		
		if (date == "" || date == null || date == undefined) {
			return "";
		}
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var year = date.getFullYear();
		var dayStr = day < 10 ? '0' + day : day;
		var monthStr = month < 10 ? '0' + month : month;
		var fromStr = year + "-" + monthStr + "-" + dayStr;
		return fromStr;
	}

	getDateFromDateString(date: any) {
		if (date == "" || date == null || date == undefined) {
			return null;
		}
		try {
			date = date.split("T")[0];
			var dateArr = date.split("-");
			date = new Date(dateArr.join("/"));
		} catch (error) {
			date = null;
		}
		return date;
	}

	public getHomeURL() {
		return "/app/dashboard";
	}

	public getDashboardURL(app: string) {
		if (app == "inventory") {
			return "/app/dashboard-inventory";
		} else if (app == "mhs") {
			return "/app/dashboard-mhs";
		} else if (app == "hc") {
			return "/app/dashboard-hc";
		} else if (app == "clip") {
			return "/app/dashboard-clip";
		}else if (app == "hcDash") {
			return "/app/dashboard-healthcube";
		} else {
			return "/app/dashboard-inventory"
		}
	}

	navigateToLogin(storeState: boolean) {
		localStorage.clear();
		localStorage.setItem("sessionexpire", "true");
		this.sendSessionExpMessage();
		if (storeState) {
			this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
		} else {
			this.router.navigate(['/login']);
		}
	}

	public currencyList() {
		return this.httpClient.get("./assets/files/currencies.json");
	}

	public loginAPI(user: any) {
		return this.httpClient.post(this.API_URL + "api/signin/", user);
	}

	public logout() {
		return this.httpClient.get(this.API_URL + "api/signOut");
	}

	public simpleGetAPICall(endpoint: string, data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams) };
		return this.httpClient.get(this.API_URL + "api/" + endpoint, options);
	}

	public simpleGetAPICallArrayBuffer(endpoint: string, data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/" + endpoint, { params: new HttpParams(httpParams), observe: 'response', responseType: 'arraybuffer' });
	}

	public TotalExpenseAppWise(payload: any) {
		return this.httpClient.post(this.API_URL + "api/TotalExpenseAppWise", payload);
	}

	public TotalSalesAppWise(payload: any) {
		return this.httpClient.post(this.API_URL + "api/TotalSalesAppWise", payload);
	}

	public sendAnalytics(payload: any) {
		return this.httpClient.post(this.API_URL + "api/analytic_data", payload ,{observe: 'response', responseType: 'arraybuffer'});
	}

	public getUserAccess() {
		const httpParams: HttpParamsOptions = { fromObject: { "id": localStorage.getItem("main_user") } } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/CdmUsersApi", { params: new HttpParams(httpParams) });
	}

	public doctorList() {
		const httpParams: HttpParamsOptions = { fromObject: { "id": localStorage.getItem("main_user") } } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/doctor_list", { params: new HttpParams(httpParams) });
	}

	public deleteUser(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "api/CdmUsersApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateUser(data: any) {
		return this.httpClient.post(this.API_URL + "api/CdmUsersApi", data);
	}

	public getUserDetails(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/CdmUsersApi", { params: new HttpParams(httpParams) });
	}

	public resetPassword(data: any) {
		return this.httpClient.post(this.API_URL + "api/changePassword/", data);
	}

	public reset2fa(data: any) {
		return this.httpClient.post(this.API_URL + "api/reset2fa", data);
	}

	public getAllModules() {
		return this.httpClient.post(this.API_URL + "api/CdmModulesApi", {});
	}

	public restoreUser(id: any) {
		return this.httpClient.get(this.API_URL + "api/restore_user/" + id + "/");
	}

	public generate_qr(data: any) {
		return this.httpClient.post(this.API_URL + "api/generate_qr", data, { responseType: "blob" });
	}

	public verifyGoogleAuthOTP(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams) };
		return this.httpClient.get(this.API_URL + "api/verify_otp", options);
	}

	public rma_dashboard_count_section(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/rma_dashboard_count_section", { params: new HttpParams(httpParams) });
	}

	public rma_dashboard_chart_section(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/rma_dashboard_chart_section", { params: new HttpParams(httpParams) });
	}

	public rma_dashboard_active_code_blue_section(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/rma_dashboard_active_code_blue_section", { params: new HttpParams(httpParams) });
	}

	public rma_dashboard_nationality_dropdown(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/rma_dashboard_nationality_dropdown", { params: new HttpParams(httpParams) });
	}

	public rma_dashboard_symptoms_dropdown(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/rma_dashboard_symptoms_dropdown", { params: new HttpParams(httpParams) });
	}

	public rma_dashboard_fleet_dropdown(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/rma_dashboard_fleet_dropdown", { params: new HttpParams(httpParams) });
	}

	public rma_dashboard_sub_symptoms_dropdown(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/rma_dashboard_sub_symptoms_dropdown", { params: new HttpParams(httpParams) });
	}

	public rma_dashboard_rank_dropdown(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/rma_dashboard_rank_dropdown", { params: new HttpParams(httpParams) });
	}

	public rma_dashboard_company_dropdown(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/rma_dashboard_company_dropdown", { params: new HttpParams(httpParams) });
	}

	public mhs_dashboard_company_dropdown(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/mhs_dashboard_company_dropdown", { params: new HttpParams(httpParams) });
	}

	public mhs_personality_and_clinical_scales() {
		return this.httpClient.get(this.API_URL + "api/get_personality_and_clinical_scales");
	}

	public rank_dropdown_data(data: any) {
		return this.httpClient.post(this.API_URL + "api/mhs_dashboard_rank_dropdown", data);
	}

	public mhs_dashboard_pie_charts_data(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.post(this.API_URL + "api/section_2_pie_charts", data);
	}

	public getKeyPersonalityTraitsScreened(data: any){
		return this.httpClient.post(this.API_URL+"api/key_peronality_traits_screened",data);
	}

	public getKeyClinicalTraitsScreened(data: any){
		return this.httpClient.post(this.API_URL+"api/key_clinical_traits_screened",data);
	}

	public rma_dashboard_table_section(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_table_section", data);
	}

	public rma_dashboard_company_period_wise_count(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_company_period_wise_count", data);
	}

	public rma_dashboard_case_symptoms_pie_chart(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_case_symptoms_pie_chart", data);
	}

	public rma_dashboard_sub_symptoms_bar_chart(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_sub_symptoms_bar_chart", data);
	}

	public get_apptts_distribution_by_comp_and_nation(data: any) {
		return this.httpClient.post(this.API_URL + "api/apptts_distribution_by_comp_and_nation", data);
	}

	public getFeedbackData(data: any) {
		return this.httpClient.post(this.API_URL + "api/feedback_graphs", data);
	}

	public mhs_dashboard_no_candidates_per_day(data: any) {
		return this.httpClient.post(this.API_URL + "api/no_of_candidates_per_day", data);
	}

	public overview_count(data: any) {
		return this.httpClient.post(this.API_URL + "api/overview_count", data);
	}

	public rma_dashboard_top_5_med_cases_graph(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_top_5_med_cases_graph", data);
	}

	public rma_dashboard_injury_cases_human_chart(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_injury_cases_human_chart", data);
	}

	public rma_dashboard_vessel_wise_count(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_vessel_wise_count", data);
	}

	public rma_dashboard_own_analysis(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_own_analysis", data);
	}

	public rma_dashboard_company_analysis(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_company_analysis", data);
	}

	public PharmaTotalShipment(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "api/PharmaTotalShipment", { params: new HttpParams(httpParams) });
	}

	public rma_dashboard_case_closure_pie_chart(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_case_closure_pie_chart", data);
	}

	public rma_dashboard_case_category_pie_chart(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_case_category_pie_chart", data);
	}

	public rma_dashboard_symptoms_tags_graph(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_symptoms_tags_graph", data);
	}
	
	public rma_dashboard_sub_symptoms_graph(data: any) {
		return this.httpClient.post(this.API_URL + "api/rma_dashboard_sub_symptoms_graph", data);
	}

	public rank_distribution_pie_chart_data(data: any) {
		return this.httpClient.post(this.API_URL + "api/rank_distribution_pie_charts", data);
	}

	public countryCodes() {
		return this.httpClient.get("./assets/files/countryCodes.json");
	}

	public deleteMDMUser(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mdm_Users", { params: new HttpParams(httpParams) });
	}

	public createUpdateMDMUser(data: FormData) {
		if (data.has("id")) {
			return this.httpClient.put(this.API_URL + "mdm/mdm_Users", data);
		} else {
			return this.httpClient.post(this.API_URL + "mdm/mdm_Users", data);
		}
	}

	public getMDMUserDetails(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "mdm/mdm_Users", { params: new HttpParams(httpParams) });
	}

	public changePasswordMDMUser(data: any) {
		return this.httpClient.put(this.API_URL + "mdm/mdm_reset_password", data);
	}

	public restoreMDMUser(id: any) {
		return this.httpClient.get(this.API_URL + "mdm/restore_mdm_user/" + id + "/");
	}

	public createUpdateUserInClip(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/clipUserApi", data);
	}

	public deleteCLIPUser(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/clipUserApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateUserInHC(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/HCUserApi", data);
	}

	public deleteHCUser(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/HCUserApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateUserInMHS(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/MHSUserApi", data);
	}

	public deleteMHSUser(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/MHSUserApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateUserInPharma(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/PharmaUserApi", data);
	}

	public deletePharmaUser(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/PharmaUserApi", { params: new HttpParams(httpParams) });
	}

	public deleteCompany(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mdm_company", { params: new HttpParams(httpParams) });
	}

	public createUpdateCompany(data: any) {
		if (data["id"]) {
			return this.httpClient.put(this.API_URL + "mdm/mdm_company", data);
		} else {
			return this.httpClient.post(this.API_URL + "mdm/mdm_company", data);
		}
	}

	public getCompanyDetails(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "mdm/mdm_company", { params: new HttpParams(httpParams) });
	}

	public restoreCompany(id: any) {
		return this.httpClient.get(this.API_URL + "mdm/restore_company/" + id + "/");
	}

	public createUpdateCompanyInClip(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/clipCompanyApi", data);
	}

	public deleteCLIPCompany(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/clipCompanyApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateCompanyInHC(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/HCCompanyApi", data);
	}

	public deleteHCCompany(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/HCCompanyApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateCompanyInMHS(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/mhsCompanyApi", data);
	}

	public deleteMHSCompany(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mhsCompanyApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateCompanyInPharma(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/PharmaCompanyApi", data);
	}

	public deletePharmaCompany(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/PharmaCompanyApi", { params: new HttpParams(httpParams) });
	}

	public deleteFleet(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mdm_fleet", { params: new HttpParams(httpParams) });
	}

	public createUpdateFleet(data: any) {
		if (data["id"]) {
			return this.httpClient.put(this.API_URL + "mdm/mdm_fleet", data);
		} else {
			return this.httpClient.post(this.API_URL + "mdm/mdm_fleet", data);
		}
	}

	public getFleetDetails(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "mdm/mdm_fleet", { params: new HttpParams(httpParams) });
	}

	public restoreFleet(id: any) {
		return this.httpClient.get(this.API_URL + "mdm/restore_fleet/" + id + "/");
	}

	public createUpdateFleetInClip(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/clipFleetApi", data);
	}

	public deleteCLIPFleet(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/clipFleetApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateFleetInHC(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/HCFleetApi", data);
	}

	public deleteHCFleet(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/HCFleetApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateFleetInMHS(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/mhsFleetApi", data);
	}

	public deleteMHSFleet(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mhsFleetApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateFleetInPharma(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/PharmaFleetApi", data);
	}

	public deletePharmaFleet(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/PharmaFleetApi", { params: new HttpParams(httpParams) });
	}

	public getFleetsByCompany(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "mdm/fleetListByCompany", { params: new HttpParams(httpParams) });
	}

	public deleteCountry(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mdm_country", { params: new HttpParams(httpParams) });
	}

	public createUpdateCountry(data: any) {
		if (data["id"]) {
			return this.httpClient.put(this.API_URL + "mdm/mdm_country", data);
		} else {
			return this.httpClient.post(this.API_URL + "mdm/mdm_country", data);
		}
	}

	public getCountryDetails(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "mdm/mdm_country", { params: new HttpParams(httpParams) });
	}

	public restoreCountry(id: any) {
		return this.httpClient.get(this.API_URL + "mdm/restore_mdm_country/" + id + "/");
	}

	public createUpdateCountryInClip(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/clipNationalityApi", data);
	}

	public deleteCLIPCountry(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/clipNationalityApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateCountryInHC(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/HCCountryApi", data);
	}

	public deleteHCCountry(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/HCCountryApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateCountryInMHS(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/mhsCountryApi", data);
	}

	public deleteMHSCountry(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mhsCountryApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateCountryInPharma(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/PharmaCountryApi", data);
	}

	public deletePharmaCountry(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/PharmaCountryApi", { params: new HttpParams(httpParams) });
	}

	public deleteRank(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mdm_rank", { params: new HttpParams(httpParams) });
	}

	public createUpdateRank(data: any) {
		if (data["id"]) {
			return this.httpClient.put(this.API_URL + "mdm/mdm_rank", data);
		} else {
			return this.httpClient.post(this.API_URL + "mdm/mdm_rank", data);
		}
	}

	public getRankDetails(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "mdm/mdm_rank", { params: new HttpParams(httpParams) });
	}

	public restoreRank(id: any) {
		return this.httpClient.get(this.API_URL + "mdm/restore_mdm_rank/" + id + "/");
	}

	public createUpdateRankInClip(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/clipRankApi", data);
	}

	public deleteCLIPRank(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/clipRankApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateRankInHC(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/HCRankApi", data);
	}

	public deleteHCRank(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/HCRankApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateRankInMHS(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/mhsRankApi", data);
	}

	public deleteMHSRank(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mhsRankApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateRankInPharma(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/PharmaRankApi", data);
	}

	public deletePharmaRank(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/PharmaRankApi", { params: new HttpParams(httpParams) });
	}

	public deleteVessel(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mdm_vessel", { params: new HttpParams(httpParams) });
	}

	public createUpdateVessel(data: any) {
		if (data["id"]) {
			return this.httpClient.put(this.API_URL + "mdm/mdm_vessel", data);
		} else {
			return this.httpClient.post(this.API_URL + "mdm/mdm_vessel", data);
		}
	}

	public getVesselDetails(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "mdm/mdm_vessel", { params: new HttpParams(httpParams) });
	}

	public restoreVessel(id: any) {
		return this.httpClient.get(this.API_URL + "mdm/restore_mdm_vessel/" + id + "/");
	}

	public createUpdateVesselInClip(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/clipVesselApi", data);
	}

	public deleteCLIPVessel(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/clipVesselApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateVesselInHC(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/HCVesselApi", data);
	}

	public deleteHCVessel(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/HCVesselApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateVesselInMHS(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/mhsVesselApi", data);
	}

	public deleteMHSVessel(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mhsVesselApi", { params: new HttpParams(httpParams) });
	}

	public createUpdateVesselInPharma(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/PharmaVesselApi", data);
	}

	public deletePharmaVessel(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/PharmaVesselApi", { params: new HttpParams(httpParams) });
	}

	public deletePoc(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mdm_poc", { params: new HttpParams(httpParams) });
	}

	public createUpdatePoc(data: any) {
		if (data["id"]) {
			return this.httpClient.put(this.API_URL + "mdm/mdm_poc", data);
		} else {
			return this.httpClient.post(this.API_URL + "mdm/mdm_poc", data);
		}
	}

	public getPocDetails(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.API_URL + "mdm/mdm_poc", { params: new HttpParams(httpParams) });
	}

	public restorePoc(id: any) {
		return this.httpClient.get(this.API_URL + "mdm/restore_mdm_poc/" + id + "/");
	}

	public createUpdatePocInClip(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/clipPOCApi", data);
	}

	public deleteCLIPPoc(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/clipPOCApi", { params: new HttpParams(httpParams) });
	}

	public createUpdatePocInHC(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/HCPOCApi", data);
	}

	public deleteHCPoc(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/HCPOCApi", { params: new HttpParams(httpParams) });
	}

	public createUpdatePocInMHS(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/mhsPOCApi", data);
	}

	public deleteMHSPoc(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/mhsPOCApi", { params: new HttpParams(httpParams) });
	}

	public createUpdatePocInPharma(data: any) {
		return this.httpClient.post(this.API_URL + "mdm/PharmaPOCApi", data);
	}

	public deletePharmaPoc(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.API_URL + "mdm/PharmaPOCApi", { params: new HttpParams(httpParams) });
	}
	public getChartData1(data:any){
		return this.httpClient.post(this.API_URL+"api/no_of_candidates_per_day/",data);
	}
	public getChartData1_1(data:any){
		return this.httpClient.post(this.API_URL+"server/Users/getChartData1_1/",data);
	}
	public getChartData1_2(data:any){
		return this.httpClient.post(this.API_URL+"server/Users/getChartData1_2/",data);
	}
	public getCasesByMonth(data:any){
		return this.httpClient.post(this.API_URL+"api/rma_dashborad_cases_by_month",data);
	}
}

export interface HttpParamsOptions {
	fromString?: string;
	fromObject?: {
		[param: string]: string | string[];
	};
	encoder?: HttpParameterCodec;
}

interface HttpParameterCodec {
	encodeKey(key: string): string
	encodeValue(value: string): string
	decodeKey(key: string): string
	decodeValue(value: string): string
}