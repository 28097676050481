import { Subscription, ReplaySubject, Subject, Observable, fromEvent, of } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter, Output } from '@angular/core';
declare var $: any;
import { debounceTime } from "rxjs/operators";
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { emailPattern, noWhitespaceInRequiredValidator, password_validation } from '../../shared/constants';

@Component({
	selector: 'app-user-create',
	templateUrl: './user-create.component.html',
	styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
	breadcrumbList = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		{
			path: "/app/users",
			name: "Users"
		}
	];
	uId: any = null;
	pagetitle = "Create User";
	moduleForm!: FormGroup;
	showLoader: boolean = false;
	showDeleteLoader: boolean = false;
	pageLoading: boolean = false;
	details: any = null;
	allAccessModules: any = [];
	allPageAccess: any = [];
	customError: any = {};
	UserModuleAccess: any = "Full";
	hideP = true;
	formSubmitted = false;

	constructor(private toastr: ToastrService, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute) {
		this.apiService.getUserAccess().subscribe((data:any) => {
			try {
				let accessObj:any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["USER_Module"];
			} catch (error) {}
		},(error:any) => {
			this.UserModuleAccess = "NoAccess";
		});
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.uId = routeParams["uId"] == undefined ? null : routeParams["uId"];
			this.moduleForm = this.formBuilder.group({
				FirstName: [null, [Validators.required, noWhitespaceInRequiredValidator]],
				LastName: [null, [Validators.required, noWhitespaceInRequiredValidator]],
				Email: [null, [Validators.required, noWhitespaceInRequiredValidator, Validators.pattern(emailPattern)]],
				Password: [null, [Validators.required, noWhitespaceInRequiredValidator, password_validation]],
				confirmPassword: [null, [Validators.required, noWhitespaceInRequiredValidator]],
				enable2fa: [false]
			}, {
				validators: this.passwordMatchValidator.bind(this)
			})
			if (this.uId) {
				this.breadcrumbList.push({ path: "/app/edit-user/" + this.uId, name: "Edit User" });
				this.pagetitle = "Edit User";
				this.pageLoading = true;
				this.getDetails();
				this.f.Password.clearValidators();
				this.f.Password.setValidators(null);
				this.f.Password.updateValueAndValidity();
				this.f.confirmPassword.clearValidators();
				this.f.confirmPassword.setValidators(null);
				this.f.confirmPassword.updateValueAndValidity();
			} else {
				this.breadcrumbList.push({ path: "/app/create-user", name: "Add User" });
				this.pageLoading = true;

				this.getAllModules();
			}
		});
	}


	getAllModules() {
		this.apiService.getAllModules().subscribe((data: any) => {
			this.pageLoading = false;
			for (let i = 0; i < data.length; i++) {
				data[i].value = "Full";
				for (let j = 0; j < data[i].sub_list.length; j++) {
					data[i].sub_list[j].value = "Full";
				}
			}
			if (this.uId) {
				try {
					if(this.details.UserModuleAccess){
						let UserModuleAccess = JSON.parse(this.details.UserModuleAccess);
						for (let i = 0; i < data.length; i++) {
							if (UserModuleAccess[data[i].key_name]) {
								data[i].value = UserModuleAccess[data[i].key_name];
							}
							for (let j = 0; j < data[i].sub_list.length; j++) {
								data[i].sub_list[j].value = UserModuleAccess[data[i].sub_list[j].key_name];
							}
						}
					}
				} catch (error) {}
			}
			
			this.allPageAccess = data;
		}, (error: any) => {
			this.pageLoading = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to get access menus.", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	formChanged() {
		this.moduleForm.markAsDirty();
	}

	getDetails() {
		this.apiService.getUserDetails({ "id": this.uId }).subscribe((data: any) => {
			this.details = data.res[0];
			this.getAllModules();
			this.f.FirstName.setValue(this.details.FirstName);
			this.f.LastName.setValue(this.details.LastName);
			this.f.Email.setValue(this.details.Email);
			this.f.enable2fa.setValue(this.details.enable2fa);
		}, (error) => {
			this.pageLoading = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to fetch details.", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	passwordMatchValidator(formGroup: FormGroup) {
		if (this.uId) {
			return null;
		}
		const password = formGroup.get('Password');
		const confirmPassword = formGroup.get('confirmPassword');
		return password!.value === confirmPassword!.value ? null : { passwordNotMatch: true };
	}

	getDateStringFromDate(date: any) {
		if (date == "" || date == null || date == undefined) {
			return "";
		}
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var year = date.getFullYear();
		var dayStr = day < 10 ? '0' + day : day;
		var monthStr = month < 10 ? '0' + month : month;
		var fromStr = year + "-" + monthStr + "-" + dayStr;
		return fromStr;
	}

	radioChanged1Old(value: any, index: number) {
		for (let i = 0; i < this.allPageAccess[index].sub_list.length; i++) {
			this.allPageAccess[index].sub_list[i].value = value;
		}
	}

	radioChanged1(e: any, index: number) {
		this.allPageAccess[index].value = e.value;
		for (let i = 0; i < this.allPageAccess[index].sub_list.length; i++) {
			this.allPageAccess[index].sub_list[i].value = e.value;
		}
	}

	radioChanged2(e: any, i: number, j:number) {
		this.allPageAccess[i].sub_list[j].value = e.value;
	}

	get f() { return this.moduleForm.controls; }

	clearCustomError() {
		Object.keys(this.f).forEach(key => {
			if (this.f[key].hasError('incorrect')) {
				try {
					delete this.f[key].errors?.incorrect;
					this.f[key].updateValueAndValidity();
				} catch (error) {

				}

			}
		});
		this.customError = {};
	}

	saveData() {
		this.formSubmitted = true;
		this.clearCustomError();
		if (this.moduleForm.invalid) {
			this.toastr.info("Please check required parameters.", 'Warning', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		let UserAccess: any = {};
		for (let i = 0; i < this.allPageAccess.length; i++) {
			UserAccess[this.allPageAccess[i].key_name] = this.allPageAccess[i].value;
			for (let j = 0; j < this.allPageAccess[i].sub_list.length; j++) {
				UserAccess[this.allPageAccess[i].sub_list[j].key_name] = this.allPageAccess[i].sub_list[j].value;
			}
		}

		let payload:any = {
			Password: this.f.Password.value,
			Email: this.f.Email.value,
			enable2fa: this.f.enable2fa.value,
			LastName: this.f.LastName.value,
			FirstName: this.f.FirstName.value,
			user_type: "cdm",
			UserModuleAccess: UserAccess
		}
		if(this.uId){
			payload["id"] = this.uId;
		}
		this.showLoader = true;
		this.apiService.createUpdateUser(payload).subscribe(data => {
			this.showLoader = false;
			this.toastr.success("Saved successfully.", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.gotoList();
		}, (error: any) => {
			this.showLoader = false;
			if (error.status == 401) { return }
			if (error.status == 409) {
				Swal.fire({
					title: 'Already Exists',
					html: 'User with email <strong>' + this.f.Email.value + '</strong> already exists. Do you want to restore it?',
					icon: 'question',
					showCancelButton: true,
					showConfirmButton: true,
					confirmButtonText: 'Yes, Restore!',
					cancelButtonText: 'Cancel',
					confirmButtonColor: '#3e4fb1'
				}).then((result) => {
					if (result.value) {
						this.apiService.restoreUser(error.error).subscribe((data: any) => {
							this.toastr.success("User restored successfully", 'Success', {
								timeOut: 3000,
								tapToDismiss: true,
								closeButton: true
							});
							this.gotoList();
						}, (error) => {
							this.toastr.error("Something went wrong", 'Error', {
								timeOut: 3000,
								tapToDismiss: true,
								closeButton: true
							});
						});
					} else if (result.dismiss === Swal.DismissReason.cancel) { }
				})
			} else {
				let errorMsg = "Something went wrong.";
				if (error.status == 400) {
					errorMsg = error.error;
					if (typeof errorMsg == 'object') {
						for (const [key, value] of Object.entries(errorMsg)) {
							let val: any = value;
							if (val[0] == "This field must be unique.") {
								val[0] = "Already exists";
							}
							if (this.moduleForm.controls[key]) {
								this.moduleForm.controls[key].setErrors({ incorrect: true });
								this.customError[key] = val[0];
								let selector = "#" + key;
								try {
									$('html, body').animate({
										scrollTop: $(selector).offset().top - 60 + 'px'
									}, 'fast');
								} catch (error) { }
								$(selector).focus();
							} else {
								this.toastr.error(key + ":" + val[0], 'Error', {
									timeOut: 3000,
									tapToDismiss: true,
									closeButton: true
								});
							}

						}
						return;
					}
				} else {
					errorMsg = error.error;
				}
				this.toastr.error(errorMsg, 'Error', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		});
	}

	showError(field:string){
		if(this.f[field].invalid && (this.formSubmitted || this.f[field].touched || this.f[field].dirty)){
			return true;
		}
		return false;
	}

	gotoList() {
		this.router.navigate(['/app/users']);
	}
}
