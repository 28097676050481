<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div class="row" *ngIf="UserModuleAccess=='Full'">
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<div class="card card-default">
			<div class="card-body">
				<div class="row">
					<div [routerLink]="['/app/mdm-users']" class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-1"
						style="cursor: pointer;">
						<div class="card1 card-shadow bg-success">
							<div class="text-center">
								<fa-icon [icon]="['fas', 'users']" size="3x"></fa-icon>
								<h3><strong>Users</strong></h3>
							</div>
						</div>
					</div>
					<div [routerLink]="['/app/company']" class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-1"
						style="cursor: pointer;">
						<div class="card1 card-shadow bg-warning">
							<div class="text-center">
								<fa-icon [icon]="['fas', 'building']" size="3x"></fa-icon>
								<h3><strong>Company</strong></h3>
							</div>
						</div>
					</div>
					<!-- <div [routerLink]="['/app/country']" class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-1"
						style="cursor: pointer;">
						<div class="card1 card-shadow bg-primary">
							<div class="text-center">
								<fa-icon [icon]="['fas', 'flag']" size="3x"></fa-icon>
								<h3><strong>Country</strong></h3>
							</div>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>