<div class="row" *ngIf="UserModuleAccess=='Full'">
	<!-- <div [routerLink]="[inventoryDashboardUrl]" class="col-lg-3 col-md-3 col-sm-12 mt-1"
						style="cursor: pointer;">
						<div class="card1 card-shadow bg-success">
							<div class="text-center">
								<fa-icon style="color: #006a39;font-size: 18px;" [icon]="['fas', 'capsules']"
									size="3x"></fa-icon>
								<h3><strong>PHARMA</strong></h3>
							</div>
						</div>
					</div>
					<div [routerLink]="[mhsDashboardUrl]" class="col-lg-3 col-md-3 col-sm-12 mt-1"
						style="cursor: pointer;">
						<div class="card1 card-shadow bg-warning">
							<div class="text-center">
								<fa-icon style="font-size: 18px;color: #856300;" [icon]="['fas', 'brain']"
									size="3x"></fa-icon>
								<h3><strong>MHS</strong></h3>
							</div>
						</div>
					</div>
					<div [routerLink]="[hcDashboardUrl]" class="col-lg-3 col-md-3 col-sm-12 mt-1"
						style="cursor: pointer;">
						<div class="card1 card-shadow bg-primary">
							<div class="text-center">
								<fa-icon style="font-size: 18px;color: #003685;" [icon]="['fas', 'ship']"
									size="3x"></fa-icon>
								<h3><strong>HEALTH CUBE</strong></h3>
							</div>
						</div>
					</div>
					<div [routerLink]="[clipDashboardUrl]" class="col-lg-3 col-md-3 col-sm-12 mt-1"
						style="cursor: pointer;">
						<div class="card1 card-shadow bg-info">
							<div class="text-center">
								<fa-icon style="font-size: 18px;color: #006376;" [icon]="['fas', 'laptop-medical']"
									size="3x"></fa-icon>
								<h3><strong>CLIP</strong></h3>
							</div>
						</div>
					</div>
</div>
<div class="row"> -->
	<div class="col-lg-3">
		<div class="card2 card-shadow">
			<!-- <fa-icon  style="font-size: 18px;" [icon]="['fas', 'capsules']" size="3x"></fa-icon> -->
			<div class="header">PHARMA</div>
			<button [routerLink]="[inventoryDashboardUrl]" class="btn btn-sm btn-primary App-button">See More</button>
		</div>
	</div>

	<div class="col-lg-3">
		<div class="card2">
			<!-- <fa-icon style="font-size: 18px;" [icon]="['fas', 'brain']" size="3x"></fa-icon> -->
			<div class="header">MHS</div>
			<button [routerLink]="[mhsDashboardUrl]" class="btn btn-sm btn-primary App-button">See More</button>
		</div>
	</div>

	<div class="col-lg-3">
		<div class="card2">
			<!-- <fa-icon style="font-size: 18px;" [icon]="['fas', 'ship']" size="3x"></fa-icon> -->
			<div class="header">HEALTH CUBE</div>
			<button [routerLink]="[hcDashboardUrl]" class="btn btn-sm btn-primary App-button">See More</button>
		</div>
	</div>

	<div class="col-lg-3">
		<div class="card2">
			<!-- <fa-icon style="font-size: 18px;" [icon]="['fas', 'laptop-medical']" size="3x"></fa-icon> -->
			<div class="header">CLIP</div>
			<button [routerLink]="[clipDashboardUrl]" class="btn btn-sm btn-primary App-button">See More</button>
		</div>
	</div>
</div>
<div class="row my-3">
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<div class="card card-default">
			<div class="card-body">
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<mat-form-field class=" me-2" appearance="outline">
							<mat-label>Year</mat-label>
							<mat-select [(value)]="filter.year" (selectionChange)="getAllData()">
								@for (year of yearList; track year) {
								<mat-option [value]="year.value">{{year.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
						<mat-form-field class="me-2" appearance="outline">
							<mat-label>Currency</mat-label>
							<mat-select [(value)]="filter.currency" (selectionChange)="getAllData()">
								<mat-option [value]="null">--Select--</mat-option>
								@for (curr of currencyList; track curr) {
								<mat-option [value]="curr.name">{{curr.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
						<mat-form-field class="me-2" appearance="outline">
							<mat-label>Payment</mat-label>
							<mat-select [(value)]="filter.is_paid" (selectionChange)="getAllData()">
								<mat-option [value]="null">--Select--</mat-option>
								@for (x of paidUnpaidList; track x) {
								<mat-option [value]="x.id">{{x.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-2 px-1">
						<div class="card card-default">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<h3>
											<img src="../assets/img/expenses.png" alt="Expenses icon" class="my-icon" />
											EXPENSES
											<span class="float-end">
												Total: {{formatAmount(totals.total_expense)}}
											</span>
										</h3>
										<hr
											style="margin-top: 10px;margin-bottom: 10px;border: 0;border-top: 1px solid #8394a97d;" />
										<div class="table-responsive">
											<table *ngIf="TotalExpenseAppWise?.length && !TotalExpenseAppWiseLoader"
												mat-table [dataSource]="TotalExpenseAppWise">
												<ng-container matColumnDef="month">
													<th mat-header-cell *matHeaderCellDef style="width: 20%;"> Month
													</th>
													<td mat-cell *matCellDef="let element"> {{element.month}} </td>
													<th mat-footer-cell *matFooterCellDef> Total </th>
												</ng-container>
												<ng-container matColumnDef="clip_amount">
													<th mat-header-cell *matHeaderCellDef class="text-end"
														style="width: 20%;">
														Shorecare
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+expense_last_updated.clip_shorecare"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.clip_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_clip_expense)}} </th>
												</ng-container>
												<ng-container matColumnDef="overhead_amount">
													<th mat-header-cell *matHeaderCellDef class="text-end"
														style="width: 20%;">
														Overheads
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.overhead_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_overhead_expense)}} </th>
												</ng-container>
												<ng-container matColumnDef="pharma_amount">
													<th mat-header-cell *matHeaderCellDef class="text-end"
														style="width: 20%;">
														Pharma (Purchase)
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+expense_last_updated.pharma_purchase"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.pharma_amount)}}
														<ng-container *ngIf="element.pharma_amount">
															({{element.pharma_count}})
														</ng-container>
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_pharma_expense)}} </th>
												</ng-container>
												<ng-container matColumnDef="freight_amount">
													<th mat-header-cell *matHeaderCellDef class="text-end"
														style="width: 20%;">
														Pharma (Vendor)
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+expense_last_updated.pharma_freight"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.freight_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_freight_expense)}} </th>
												</ng-container>
												<tr mat-header-row *matHeaderRowDef="columnsTotalExpenseAppWise"></tr>
												<tr mat-row *matRowDef="let row; columns: columnsTotalExpenseAppWise;">
												</tr>
												<tr mat-footer-row *matFooterRowDef="columnsTotalExpenseAppWise"></tr>
											</table>
											<p *ngIf="TotalExpenseAppWise?.length == 0 && !TotalExpenseAppWiseLoader"
												class="text-center">
												No data found!
											</p>
											<p *ngIf="TotalExpenseAppWiseLoader" class="text-center text-bold">
												<mat-spinner style="margin: 0 auto;"></mat-spinner>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-2 px-1">
						<div class="card card-default">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<h3>
											<img src="../assets/img/sales.png" alt="Sales icon" class="my-icon" />
											SALES
											<span class="float-end">
												Total: {{formatAmount(totals.total_sales)}}
											</span>
										</h3>
										<hr
											style="margin-top: 10px;margin-bottom: 10px;border: 0;border-top: 1px solid #8394a97d;" />
										<div class="table-responsive">
											<table *ngIf="TotalSalesAppWise?.length && !TotalSalesAppWiseLoader"
												mat-table [dataSource]="TotalSalesAppWise">
												<ng-container matColumnDef="month">
													<th mat-header-cell *matHeaderCellDef style="width: 10%;"> Month
													</th>
													<td mat-cell *matCellDef="let element"> {{element.month}} </td>
													<th mat-footer-cell *matFooterCellDef> Total </th>
												</ng-container>
												<ng-container matColumnDef="shorecare_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 15%;" class="text-end">
														Shorecare
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+sales_last_updated.clip_shorecare"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.shorecare_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_clip_shorecare)}} </th>
												</ng-container>
												<ng-container matColumnDef="training_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 15%;" class="text-end">
														Training
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.training_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_training_sales)}} </th>
												</ng-container>
												<ng-container matColumnDef="clip_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 15%;" class="text-end">
														CLIP
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+sales_last_updated.clip_subscribe"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.clip_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_clip_sales)}} </th>
												</ng-container>
												<ng-container matColumnDef="pharma_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 15%;" class="text-end">
														Pharma
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+sales_last_updated.pharma_shipment"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.pharma_amount)}}
														<ng-container *ngIf="element.pharma_amount">
															({{element.pharma_count}})
														</ng-container>
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_pharma_sales)}} </th>
												</ng-container>
												<ng-container matColumnDef="mhs_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 15%;" class="text-end">
														MHS
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+sales_last_updated.mhs_bill"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.mhs_amount)}}
														<ng-container *ngIf="element.mhs_amount">
															({{element.mhs_count}})
														</ng-container>
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_mhs_sales)}} </th>
												</ng-container>
												<ng-container matColumnDef="hc_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 15%;" class="text-end">
														RMA
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+sales_last_updated.hc_bill"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.hc_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_hc_sales)}} </th>
												</ng-container>
												<tr mat-header-row *matHeaderRowDef="columnsTotalSalesAppWise"></tr>
												<tr mat-row *matRowDef="let row; columns: columnsTotalSalesAppWise;">
												</tr>
												<tr mat-footer-row *matFooterRowDef="columnsTotalSalesAppWise"></tr>
											</table>
											<p *ngIf="TotalSalesAppWise?.length == 0 && !TotalSalesAppWiseLoader"
												class="text-center">
												No data found!
											</p>
											<p *ngIf="TotalSalesAppWiseLoader" class="text-center text-bold">
												<mat-spinner style="margin: 0 auto;"></mat-spinner>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>