import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';

import { Component, OnInit, ViewChild, NgZone, HostListener, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterOutlet } from "@angular/router";
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceInRequiredValidator, password_validation } from '../shared/constants';
import { slideInAnimation } from '../animations/route-animation';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrl: './navbar.component.scss',
	animations: [slideInAnimation]
})
export class NavbarComponent implements OnInit, AfterViewChecked {
	title = 'material-responsive-sidenav';
	@ViewChild(MatSidenav) sidenav!: MatSidenav;
	isMobile = true;
	isCollapsed = true;
	logourl = "../assets/img/cubelogo.png";
	year: number = new Date().getFullYear();
	resetPasswordForm!: FormGroup;
	user_name = localStorage.getItem("first_name") + " " + localStorage.getItem("last_name");
	screenHeight = screen.height - 200;
	UserModuleAccess: any = null;
	inventoryDashboardUrl = this.apiService.getDashboardURL("inventory");
	mhsDashboardUrl = this.apiService.getDashboardURL("mhs");
	hcDashboardUrl = this.apiService.getDashboardURL("hc");
	hcNewDashboardUrl = this.apiService.getDashboardURL("hcDash");
	clipDashboardUrl = this.apiService.getDashboardURL("clip");
	homeUrl = this.apiService.getHomeURL();

	constructor(private changeRef: ChangeDetectorRef, private observer: BreakpointObserver, private toastr: ToastrService, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private zone: NgZone) { }

	ngAfterViewChecked(): void {
		this.changeRef.detectChanges();
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.observer.observe(['(max-width: 800px)']).subscribe((screenSize) => {
				if (screenSize.matches) {
					this.isMobile = true;
				} else {
					this.isMobile = false;
				}
			});
			this.resetPasswordForm = this.formBuilder.group({
				password: ['', [Validators.required, noWhitespaceInRequiredValidator, password_validation]],
				confirmPassword: ['', [Validators.required, noWhitespaceInRequiredValidator]],
			}, {
				validators: this.passwordMatchValidator.bind(this)
			});
		});

	}

	toggleMenu() {
		if (this.isMobile) {
			this.sidenav.toggle();
			this.isCollapsed = false; // On mobile, the menu can never be collapsed
		} else {
			this.sidenav.open(); // On desktop/tabs, the menu can never be fully closed
			this.isCollapsed = !this.isCollapsed;
		}
	}

	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
	}

	passwordMatchValidator(formGroup: FormGroup) {
		const password = formGroup.get('password');
		const confirmPassword = formGroup.get('confirmPassword');
		return password!.value === confirmPassword!.value ? null : { passwordNotMatch: true };
	}

	get f() { return this.resetPasswordForm.controls; }

	resetPasswordModal() {
		$("#changePasswordModal").modal("show");
	}

	resetPassword() {
		if (this.resetPasswordForm.invalid) {
			return;
		}
		let data = {
			username: localStorage.getItem("emailId"),
			password: this.f.password.value
		}
		this.apiService.resetPassword(data).subscribe(data => {
			this.clearResetPassword();
			this.toastr.info("Password changed successfully.", 'Success!!!', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.logoutUser();
		}, (error) => {
			if (error.status == 401) { return }
			this.toastr.error("Error while changing password.", 'Error!', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	clearResetPassword() {
		$("#changePasswordModal").modal("hide");
		this.f.password.setValue("");
		this.f.confirmPassword.setValue("");
		this.f.password.updateValueAndValidity();
		this.f.confirmPassword.updateValueAndValidity();
		this.resetPasswordForm.markAsUntouched();
		this.resetPasswordForm.reset();
	}

	logoutUser() {
		this.apiService.logout().subscribe(data => {
			this.apiService.navigateToLogin(false);
		}, (error) => {
			this.apiService.navigateToLogin(false);
		});
	}

	scrollToTop() {
		let selector = "#page-content-container-common";
		try {
			$('html, body').animate({
				scrollTop: $(selector).offset().top + 'px'
			}, 'slow');
		} catch (error) { }
	}

	showScrollBtn = false;
	@HostListener('window:scroll', ['$event'])
	onScroll(e: any) {
		try {
			if ($('#main-menu-header').offset().top) {
				this.showScrollBtn = true;
			} else {
				this.showScrollBtn = false;
			}
		} catch (error) { }
	}
}
