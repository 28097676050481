import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService,HttpParamsOptions} from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { LegendPosition } from '@swimlane/ngx-charts';
import {ProgressBarMode} from '@angular/material/progress-bar';
import { trigger, style, transition, animate } from '@angular/animations';
import { NgZone } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
declare var $: any;
import { DataTableDirective } from 'angular-datatables';
import { Config } from 'datatables.net';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome'; // Import library to add icons
import { faDownload, faChartArea,faSearch } from '@fortawesome/free-solid-svg-icons'; // Import specific icons

class DataTablesResponse {
	data!: any[];
	draw!: number;
	recordsFiltered!: number;
	recordsTotal!: number;
}

@Component({
	selector: 'app-dashboard-hc',
	templateUrl: './dashboard-healthcube.component.html',
	styleUrls: ['./dashboard-healthcube.component.scss', '../../assets/styles/dashboard-styles.scss'],
	animations: [trigger('enterAnimation', [
		transition(':enter', [
			style({ transform: 'translateY(-100%)', opacity: 0 }),
			animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
		]),
		transition(':leave', [
			style({ transform: 'translateY(0)', opacity: 1 }),
			animate('500ms', style({ transform: 'translateY(-100%)', opacity: 0 }))
		])
	])]
})
export class DashboardHealthcubeComponent implements OnInit, AfterViewInit, OnDestroy {
	UserModuleAccess: any = "Full";
	showCard = false;
	colorScheme:any = {
		domain: ['#5AA454', '#ffe417', '#f72119', '#ffa01b', '#690000']
	  };
	  filterDates = (d : Date | null)=>{
		const today = new Date();
		if(d == null) return false;
		return d <= today;
	  }
	// options
	@ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;
	@ViewChild(MatPaginator,) paginator!: MatPaginator;
	baseURL = environment.API_URL + "api/";
	dtOptions: Config = {};
	dataList: any = [];
	legend: boolean = true;
	showLabels: boolean = true;
	animations: boolean = true;
	xAxis: boolean = true;
	yAxis: boolean = true;
	showYAxisLabel: boolean = true;
	showXAxisLabel: boolean = true;
	timeline: boolean = true;
	mode: ProgressBarMode = 'determinate';
  	value = 50;
  	bufferValue = 75;
	bubbles:any
	balls: Array<{top: number, left: number, vx: number, vy: number, text: string , num: number,bsize:any}> = [];
	containerWidth = 450;
	containerHeight = 420;
	ballSize = 50;
	intervalId: any;
	displayedColumns: string[] = ['sn','name', 'value'];  // Columns to display in the table
	dataSource = new MatTableDataSource<any>();        // Data source for the table
	totalItems = 100;                                        // Total number of items (used for pagination)
	pageSize = 10;   
	selectDoctor:any = [];
	
	params:any = {
		// fromDate:new Date(new Date().setDate(new Date().getDate() - 120)),
		// toDate:new Date(),
		selectDoctor : "",
		caseStatus: ""
	};
	caseStatus:any = ['Open', 'Closed','Repatriation' , 'Reopen']
	//----------------------//
	constructor(
		library: FaIconLibrary,
		private formBuilder: FormBuilder,
		private apiService: ApiService, 
		private activeRoute: ActivatedRoute, 
		private toastr: ToastrService,
		private zone: NgZone,
		private datePipe: DatePipe,
		private http: HttpClient
	) {
		library.addIcons(faDownload,faChartArea,faSearch); 
		this.apiService.getUserAccess().subscribe((data: any) => {
			try {
				this.cardsFilter.user_id = (data.res[0].UserId);
				
				let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				//console.log(accessObj);
				this.UserModuleAccess = accessObj["HC_dashboard"];
			} catch (error) { }
		}, (error: any) => {
			this.UserModuleAccess = "NoAccess";
		});
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.initDatatable();
			this.doctor_list();
			this.rma_dashboard_symptoms_tags_graph();
			this.rma_dashboard_sub_symptoms_graph(null);
    		this.moveBalls();
			this.rma_dashboard_symptoms_dropdown();
			this.rma_dashboard_nationality_dropdown();
			this.rma_dashboard_company_dropdown();
			this.rma_dashboard_rank_dropdown();
			this.getCompanyWiseData();
		});
	}

	ngAfterViewInit(): void {}

	ngOnDestroy(): void {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		  }
	 }
	   // Fetch data from API
	   vesselLoader = false;
	   


	initDatatable() {
		this.dtOptions = {
			pagingType: 'full_numbers',
			serverSide: true,
			processing: true,
			ordering: true,
			ajax: (dataTablesParameters: any, callback) => {
				let data: any = {
					draw: dataTablesParameters.draw,
					start: dataTablesParameters.start,
					length: dataTablesParameters.length,
					company_id: this.cardsFilter.company_id,
					start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
					end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
					rank: this.cardsFilter.rank,
					nationality: this.ownAnalysisFilter.nationality,
					searchVal: ""
				}
				if (dataTablesParameters.search.value != "") {
					data.searchVal = dataTablesParameters.search.value;
				}
				const httpParams: HttpParamsOptions = { data } as HttpParamsOptions;
				console.log(httpParams);
				this.http.post<DataTablesResponse>(this.baseURL + "rma_dashboard_vessel_wise_count", data).subscribe((response: any) => {
					
					this.dataList = response.data_list;
					callback({
						recordsTotal: response.total_appt_vessel_count,
						recordsFiltered: response.total_appt_vessel_count,
						data: []
					});
				}, (error: any) => {
					if (error.status == 401) { return }
					this.toastr.error("Hmmm Something went wrong.", 'Error!!!', {
						timeOut: 5000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			}
		}
	}
	
	  
	  doctor_list():void {
		this.apiService.doctorList().subscribe((data: any) => {
			//console.log(data);
			this.selectDoctor = data.doctors;
		});
	}

	  // Filter search results
	  applyFilter(event: Event): void {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	
		if (this.dataSource.paginator) {
		  this.dataSource.paginator.firstPage();
		}
	  }
	
	  // Handle pagination
	  onPageChange(event: PageEvent): void {
		this.pageSize = event.pageSize;
	  }
		  //symptoms_tag_data: any = [];
		  rma_dashboard_symptoms_tags_graph() {
			let filter = {
				company_id: this.cardsFilter.company_id,
				start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
				end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
				rank: this.cardsFilter.rank,
				nationality:this.ownAnalysisFilter.nationality,
				symptom:"INJURY"
			};
			  this.apiService.rma_dashboard_symptoms_tags_graph(filter).subscribe((data: any) => {
				let bubbleData: any[] = [];
				for(let i=0; i< data.data_list.length ; i++ ){
						bubbleData.push({
						name: data.data_list[i].name,
						value: data.data_list[i].value,
						size:`${(data.data_list[i].value)}px`,
						color: "#555555"
					//	color: this.getRandomColor()
					});
				}
				 this.bubbles= bubbleData;
				 this.generateBalls(this.bubbles);
				 //console.log(this.bubbles);
			  }, (error) => {
				  this.bubbles = [];
			  });
		  }
	  // Generate balls with random positions, velocities, and text
	  generateBalls(balls: any[]): void {
		const { containerHeight, containerWidth, ballSize } = this;
	  
		this.balls = balls.map(({ name, value }) => {
		  const randomTop = Math.random() * (containerHeight - ballSize);
		  const randomLeft = Math.random() * (containerWidth - ballSize);
		  const randomVX = Math.random() * 0.9; // Velocity in X direction
		  const randomVY = Math.random() * 0.9; // Velocity in Y direction
	  
		  return {
			top: randomTop,
			left: randomLeft,
			vx: randomVX,
			vy: randomVY,
			text: name,
			num: value,
			bsize: `${value}px`
		  };
		});
	  }
	  
	//   generateBalls(balls:any): void {
	// 	let numBalls = balls.length;

	// 	for (let i = 0; i < numBalls; i++) {
	// 	  const randomTop = Math.random() * (this.containerHeight - this.ballSize);
	// 	  const randomLeft = Math.random() * (this.containerWidth - this.ballSize);
	// 	  const randomVX = Math.random() *0.9; // Velocity in X direction
	// 	  const randomVY = Math.random() *0.9; // Velocity in Y direction
	// 	  const randomText = balls[i].name;
	
	// 	  this.balls.push({
	// 		top: randomTop,
	// 		left: randomLeft,
	// 		vx: randomVX,
	// 		vy: randomVY,
	// 		text: randomText,
	// 		num: balls[i].value,
	// 		bsize:`${balls[i].value}px`
	// 	  });
	// 	}
	//   }
	
	  // Move balls inside the container
	  moveBalls(): void {
		this.intervalId = setInterval(() => {
		  this.balls.forEach(ball => {
			ball.top += ball.vy;
			ball.left += ball.vx;
	
			// Check for boundary collision and reverse direction if needed
			if (ball.top <= 0 || ball.top >= this.containerHeight - this.ballSize) {
			  ball.vy = -ball.vy; // Reverse Y direction
			}
			if (ball.left <= 0 || ball.left >= this.containerWidth - this.ballSize) {
			  ball.vx = -ball.vx; // Reverse X direction
			}
		  });
		}, 20); // Update every 20ms
	  }
	//   deselectByIdClinical(id:any){
	// 	var arr:any = this.f.clinicalScale1.value;
	// 	const index = arr.indexOf(id);
	// 	arr.splice(index,1);
	// 	arr = [...arr];
	// }
	// getCompanyWiseData() {
	// 	this.initDatatable();
	// 	this.rma_dashborad_cases_by_month();
	// 	this.rma_dashboard_case_category_pie_chart();
	// 	this.rma_dashboard_company_period_wise_count();
	// 	this.rma_dashboard_top_5_med_cases_graph();
	// 	this.humanAnatomyAPIs();
	// 	this.rma_dashboard_symptoms_tags_graph();
	// 	this.rma_dashboard_sub_symptoms_graph(null);
	// }

	getCompanyWiseData() {
		this.initDatatable();
		
		// Group API calls to improve readability and ensure they run in parallel
		this.loadRmaDashboardData();
		this.humanAnatomyAPIs();
	}
	
	loadRmaDashboardData() {
		const dashboardAPIs = [
			this.rma_dashborad_cases_by_month(),
			this.rma_dashboard_case_category_pie_chart(),
			this.rma_dashboard_company_period_wise_count(),
			this.rma_dashboard_top_5_med_cases_graph(),
			this.rma_dashboard_symptoms_tags_graph(),
			this.rma_dashboard_sub_symptoms_graph(null),
		];
	
		// Execute all dashboard-related API calls in parallel
		Promise.all(dashboardAPIs).catch(error => {
			console.error('Error loading RMA dashboard data:', error);
		});
	}

	pagetitle = "Health Cube";
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		{
			path: this.apiService.getDashboardURL("hc"),
			name: this.pagetitle
		}
	];
	dateFilter = this.apiService.getDefaultDateRange(true);
	currentAccordion = "InvoiceModuleAlerts";
	symptoms: string[] = ['ILLNESS', 'PSYCHOLOGICAL', 'SURGERY', 'INJURY', 'ROUTINE HEALTH CHECK'];
	selectedSymptom: string = this.symptoms[3]; 
	
	getColorClass(value: number): string {
		if (value >= 1 && value <= 9) {
			return 'range-1-9';
		  } else if (value >= 10 && value <= 25) {
		  return 'range-10-25';
		} else if (value >= 26 && value <= 50) {
		  return 'range-26-50';
		} else if (value >= 51 && value <= 75){
			return 'range-51-75';
		}else if (value >= 76 && value <= 100){
			return 'range-76-10000';
		} if (value >= 101 && value <= 10000){
			return 'range-101-10000';
		}else {
			return '';
		}
	  }

	  onSelect(e:any):void{
		this.rma_dashboard_sub_symptoms_graph(e.name);
	  }
	  top_5_med_cases:any[]=[];
	  rma_dashboard_top_5_med_cases_graph(): void {
		const { company_id, start_date, end_date, rank } = this.cardsFilter;
		const { nationality } = this.ownAnalysisFilter;
	  	const filter = {
		  company_id,
		  start_date: this.apiService.getDateStringFromDate(start_date),
		  end_date: this.apiService.getDateStringFromDate(end_date),
		  rank,
		  nationality
		};	  
		this.apiService.rma_dashboard_top_5_med_cases_graph(filter).subscribe(
		  ({ data_list }: any) => {
			this.top_5_med_cases = data_list.map(({ name, value }: any) => ({ name, value }));
		  },() => {
			this.top_5_med_cases = [];
		  }
		);
	  }
	  
	//   rma_dashboard_top_5_med_cases_graph(): void {
	// 	const filter = {
	// 		company_id: this.cardsFilter.company_id,
	// 		start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
	// 		end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
	// 		rank: this.cardsFilter.rank,
	// 		nationality: this.ownAnalysisFilter.nationality
	// 	};
	// 	this.apiService.rma_dashboard_top_5_med_cases_graph(filter).subscribe((data: any) => {
	// 			this.top_5_med_cases = data.data_list.map((item: any) => ({
	// 				name: item.name,
	// 				value: item.value
	// 			}));
	// 		},() => {
	// 			this.top_5_med_cases = [];
	// 		}
	// 	);
	// }
	




	symptoms_dropdown: any = [];
	rma_dashboard_symptoms_dropdown() {
		this.apiService.rma_dashboard_symptoms_dropdown({}).subscribe((data: any) => {
			this.symptoms_dropdown = data.symptoms_list;
		}, (error) => {
			this.symptoms_dropdown = [];
		});
	}

	subSymptoms_dropdown1: any = [];
	subSymptoms_dropdown2: any = [];
	rma_dashboard_sub_symptoms_dropdown(symptomFilter: number) {
		let payload: any = {};
		if (symptomFilter == 1) {
			payload["symptom"] = this.ownAnalysisFilter.symptom ? this.ownAnalysisFilter.symptom.hc_id : null;
		} else if (symptomFilter == 2) {
			payload["symptom"] = this.cardsFilter.symptom ? this.cardsFilter.symptom.hc_id : null;
		}
		if (!payload["symptom"]) {
			if (symptomFilter == 1) {
				this.subSymptoms_dropdown1 = [];
			} else if (symptomFilter == 2) {
				this.subSymptoms_dropdown2 = [];
			}
			return;
		}
		this.apiService.rma_dashboard_sub_symptoms_dropdown(payload).subscribe((data: any) => {
			if (symptomFilter == 1) {
				this.subSymptoms_dropdown1 = data.sub_symptoms_rows;
			} else if (symptomFilter == 2) {
				this.subSymptoms_dropdown2 = data.sub_symptoms_rows;
			}
		}, (error) => {
			if (symptomFilter == 1) {
				this.subSymptoms_dropdown1 = [];
			} else if (symptomFilter == 2) {
				this.subSymptoms_dropdown2 = [];
			}
		});
	}

	nationalities_dropdown: any = [];
	rma_dashboard_nationality_dropdown() {
		this.apiService.rma_dashboard_nationality_dropdown({}).subscribe((data: any) => {
			this.nationalities_dropdown = data.nationality_list;
		}, (error) => {
			this.nationalities_dropdown = [];
		});
	}

	companyList_dropdown: any = [];
	rma_dashboard_company_dropdown() {
		this.apiService.rma_dashboard_company_dropdown({}).subscribe((data: any) => {
			this.companyList_dropdown = data.company_list;
		}, (error) => {
			this.companyList_dropdown = [];
		});
	}
	//sym_card_data:any = [];
	// getAllTags(data) {
	// 	this.rma_dashboard_symptoms_tags_graph();
	// 	this.sym_card_data = data;
	// 	this.showCard = true;
	//   }
	  
	//   mouseLeave() {
	// 	this.showCard  = false;
	//   }
	  
	  progress_bar_data: any = [];
	  subCatName: any = [];
	  rma_dashboard_sub_symptoms_graph(sym: any | null) {
		const filter = {
		  company_id: this.cardsFilter.company_id,
		  rank: this.cardsFilter.rank,
		  nationality: this.ownAnalysisFilter.nationality,
		  start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
		  end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
		  symptom: sym || "ILLNESS"
		};
	  
		this.apiService.rma_dashboard_sub_symptoms_graph(filter).subscribe(
		  (data: any) => {
			const total = data.total_appt_sub_symp_count;
			this.subCatName = filter.symptom;
			this.progress_bar_data = data.data_list.map((item: any) => ({
			  name: item.name,
			  value: ((item.value / total) * 100).toFixed(2)
			}));
		  },
		  () => {
			this.progress_bar_data = [];
		  }
		);
	  }
	  

	//   rma_dashboard_sub_symptoms_graph(sym:any | null) {
	// 	let def = "ILLNESS"
	// 	let filter = {
	// 		company_id: this.cardsFilter.company_id,
	// 		rank: this.cardsFilter.rank,
	// 		nationality:this.ownAnalysisFilter.nationality,
	// 		start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
	// 		end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
	// 		symptom:sym || def
	// 	};
	// 	  this.apiService.rma_dashboard_sub_symptoms_graph(filter).subscribe((data: any) => {
	// 		this.progress_bar_data = data.data_list;
	// 		let total = data.total_appt_sub_symp_count;
	// 		let total_data: any = [];
	// 		for (let i = 0 ; i < this.progress_bar_data.length; i++) {
	// 			total_data.push({
	// 				name: data.data_list[i].name,
	// 				value: ((data.data_list[i].value  / total)* 100).toFixed(2),
				
	// 			});
	// 		}
	// 		this.progress_bar_data= total_data;
	// 		 // console.log(this.progress_bar_data);
	// 	  }, (error) => {
	// 		  this.progress_bar_data = [];
	// 	  });
	//   }

	  pinobj: any = {};
	  editHumanBody = false;
	  humanAnatomyPoints: any = '';
	  allPainPointsOfAnatomy: any = [];
		spotsconfiga: any;
		spotsconfigb: any;
		spotsconfiga2: any;
		spotsconfigb2: any;
	  selectedPoints: any = [];

	  humanAnatomyAPIs() {
		// Initialize configurations
		this.spotsconfiga = { pins: [] };
		this.spotsconfigb = { pins: [] };
		this.spotsconfiga2 = [];
		this.spotsconfigb2 = [];
	
		const filter = {
			company_id: this.cardsFilter.company_id,
			rank: this.cardsFilter.rank,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
			nationality: this.ownAnalysisFilter.nationality,
			symptom: this.selectedSymptom,
		};
	
		this.apiService.rma_dashboard_injury_cases_human_chart(filter).subscribe({
			next: (data: any) => {
				const { MF_data_list, MB_data_list } = data;
				this.spotsconfiga.pins = MF_data_list;
				this.spotsconfigb.pins = MB_data_list;
				this.displayHumanAnatomy();
			},
			error: (error) => {
				console.error("Error fetching data:", error);
				// Handle error appropriately here (e.g., show a message)
			}
		});
	}
	

	displayHumanAnatomy() {
		const createSvgCircle = (pins:any, spotsConfig:any, svgContainerId) => {
			const xmlns = "http://www.w3.org/2000/svg";
			const svgContainer = document.getElementById(svgContainerId);
			
			pins.forEach((pin:any, index:any) => {
				const isSelected = spotsConfig.some(config => config.human_anatomy_id__id === pin.human_anatomy_id__id);
				const color = isSelected ? pin.selectedColor : pin.upColor;
	
				$(`#${svgContainerId}_${index}`).remove();
	
				const svgCircle = document.createElementNS(xmlns, "circle");
				svgCircle.setAttributeNS(null, "cx", pin.human_anatomy_id__x_axis);
				svgCircle.setAttributeNS(null, "cy", pin.human_anatomy_id__y_axis);
				svgCircle.setAttributeNS(null, "r", "15");
				svgCircle.setAttributeNS(null, "fill", "#f72119");
				svgCircle.setAttributeNS(null, "stroke", color);
				svgCircle.setAttributeNS(null, "stroke-width", "1");
				svgCircle.setAttributeNS(null, "cursor", "pointer");
				svgCircle.setAttributeNS(null, "id", `${svgContainerId}_${index}`);
				svgCircle.setAttributeNS(null, "body_part", pin.human_anatomy_id__body_part);
	
				const title = document.createElementNS(xmlns, "title");
				title.textContent = `Body Part: ${pin.human_anatomy_id__body_part} / Cases: ${pin.dcount}`;
				title.setAttributeNS(null, "class", 'title');
	
				svgCircle.appendChild(title);
				if (svgContainer) {
					svgContainer.appendChild(svgCircle);
				}
			});
		};
	
		const spotsConfigA = this.spotsconfiga.pins || [];
		const spotsConfigB = this.spotsconfigb.pins || [];
		
		createSvgCircle(spotsConfigA, this.spotsconfiga2, "anaspotsa");
		createSvgCircle(spotsConfigB, this.spotsconfigb2, "anaspotsb");
	}
	
	
	remove(item: any) {
		let index = this.selectedPoints.indexOf(item);
		this.selectedPoints.splice(index, 1);
	}

	ranks_dropdown: any = [];
	rma_dashboard_rank_dropdown() {
		this.apiService.rma_dashboard_rank_dropdown({}).subscribe((data: any) => {
			this.ranks_dropdown = data.rank_list;
		}, (error) => {
			this.ranks_dropdown = [];
		});
	}


	company_period_wise_count_loader = false;
	company_period_wise_count: any = {};
	cardsFilter: any = {
		symptom: null,
		company_id: null,
		fleet_id: null,
		rank: null,
		start_date_analytics:this.apiService.getDefaultDateRange3Months(false).start_date,
		end_date_analytics:this.apiService.getDefaultDateRange3Months(false).end_date,
		user_id:null,
		start_date: this.apiService.getDefaultDateRange3Months(false).start_date,
		end_date: this.apiService.getDefaultDateRange3Months(false).end_date
	}
	arc_width = 0.25;
	pieChartLegendPosition: LegendPosition = LegendPosition.Right;
	pieChartLegendPositionBelow: LegendPosition = LegendPosition.Below;
	
	colorArr = ["#198754","#FFC107","#0D6EFD","#0DCAF0","#DC3545","#6C757D","#6b5b95","#86af49",
		"#c94c4c","#bc5a45","#EF46DD","#D75D5E","#4B0082","#005792","#6643b5"
	];
	getRandomColor() {
		const letters = '0123456789ABCDEF';
		let color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}
	view: any[] = [700, 300];
	symptomPieChartColors: any[] = [];
	symptoms_chart_loader = false;
	symptoms_chart_data: any = [];
	rma_dashboard_company_period_wise_count() {
		const { company_id, fleet_id, rank, start_date, end_date } = this.cardsFilter;
		
		const filter = {
			company_id,
			fleet_id,
			rank,
			start_date: this.apiService.getDateStringFromDate(start_date),
			end_date: this.apiService.getDateStringFromDate(end_date),
		};
	
		this.company_period_wise_count_loader = true;
	
		this.apiService.rma_dashboard_company_period_wise_count(filter).subscribe({
			next: (data: any) => {
				this.company_period_wise_count = data;
			},
			error: () => {
				this.company_period_wise_count = {};
			},
			complete: () => {
				this.company_period_wise_count_loader = false;
			}
		});
	}
	

	cases_by_month_data: any[] = [];
	case_loader:any = false;
	rma_dashborad_cases_by_month(): void {
		const filter = {
			company_id: this.cardsFilter.company_id,
			rank: this.cardsFilter.rank,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
			nationality: this.ownAnalysisFilter.nationality
		};
		this.case_loader = true;
		this.apiService.getCasesByMonth(filter).subscribe((data: any) => {
				const seriesData = data.data_list.slice(0, 12).map((item: any) => ({
					name: item.name,
					value: item.value
				}));
	
				this.cases_by_month_data = [{
					name: "Cases",
					series: seriesData
				}];
				this.case_loader = false;
			},
			() => {
				this.cases_by_month_data = [];
				this.case_loader = true;
			}
		);
	}
	
	// rma_dashborad_cases_by_month(){
	// 	let filter = {
	// 		company_id: this.cardsFilter.company_id,
	// 		rank: this.cardsFilter.rank,
	// 		start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
	// 		end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
	// 		nationality:this.ownAnalysisFilter.nationality,
	// 	};
	// 	let chartData: any[] = [];
	// 	let seriesData: any[] = [];
	// 	this.apiService.getCasesByMonth(filter).subscribe((data: any) => {
			
	// 		for (let i = 0; i < 12; i++) {
	// 			seriesData.push({name:data.data_list[i].name,value:data.data_list[i].value})
	// 		}
	// 			chartData.push({
	// 				name: "Cases",
	// 				series:seriesData
	// 			});
			
	// 		//console.log(chartData);
	// 		this.cases_by_month_data= chartData;
	// 	}, (error) => {
	// 		this.cases_by_month_data = [];
	// 	});

	// }


	ownAnalysisFilter: any = {
		symptom: null,
		sub_symptom: null,
		company_id: null,
		fleet_id: null,
		nationality: null,
		rank: null,
		age: "",
		start_date: this.apiService.getDefaultDateRange3Months(false).start_date,
		end_date: this.apiService.getDefaultDateRange3Months(false).end_date,
		case_status: null
	}

	case_category_pie_chart_data: any[] = [];
	case_closure_pie_chart_colors: any[] = [];
	case_cat:any = false;
	rma_dashboard_case_category_pie_chart() {
		const filter = {
		  company_id: this.cardsFilter.company_id,
		  start_date: this.apiService.getDateStringFromDate(this.ownAnalysisFilter.start_date),
		  end_date: this.apiService.getDateStringFromDate(this.ownAnalysisFilter.end_date),
		  rank: this.cardsFilter.rank,
		  nationality: this.ownAnalysisFilter.nationality,
		};
		this.case_cat = true;
		this.apiService.rma_dashboard_case_category_pie_chart(filter).subscribe(
		  (data: any) => {
			const total_rank = data.total_appt_symp_count;
			this.case_category_pie_chart_data = data.data_list.map((item: any) => ({
			  name: item.name,
			  value: ((item.value / total_rank) * 100).toFixed(2),
			}));
			this.case_cat = false;
		  },
		  () => {
			this.case_cat = true;
			this.case_category_pie_chart_data = [];
			this.case_closure_pie_chart_colors = [];
		  }
		);
	  }
	  
	// rma_dashboard_case_category_pie_chart() {
	// 	let filter = {
	// 		company_id:  this.cardsFilter.company_id,
	// 		start_date: this.apiService.getDateStringFromDate(this.ownAnalysisFilter.start_date),
	// 		end_date: this.apiService.getDateStringFromDate(this.ownAnalysisFilter.end_date),
	// 		rank :this.cardsFilter.rank,
	// 		nationality:this.ownAnalysisFilter.nationality,
			
	// 	};
	// 	this.apiService.rma_dashboard_case_category_pie_chart(filter).subscribe((data: any) => {
	// 		let chartData: any[] = [];
	// 		let total_rank=data.total_appt_symp_count;
	// 		for (let i = 0; i < data.data_list.length; i++) {
	// 			chartData.push({
	// 								name: data.data_list[i].name,
	// 								value: ((data.data_list[i].value  / total_rank) * 100).toFixed(2) 
	// 							});
	// 						}
	// 		console.log(chartData);
	// 		this.case_category_pie_chart_data = chartData;
	// 	}, (error) => {
	// 		this.case_category_pie_chart_data = [];
	// 		this.case_closure_pie_chart_colors = [];
	// 	});
	// }

	dateFormat(date){

		const year = date.getFullYear();
		const month = ('00' + (date.getMonth() + 1)).slice(-2); // Pad with zero
		const day = ('00' + date.getDate()).slice(-2); // Pad with zero
		return `${year}-${month}-${day}`;
	}

	getTime(){
		let formattedDate:any = this.datePipe.transform(new Date(), 'dd/MM/YYYY, h:mm:ss a');
		formattedDate = formattedDate.replaceAll(/[, ]/g,'__');
		return formattedDate;
	}

	sendAnalytics():void {
		let analyticsData = {
			user_id:  this.cardsFilter.user_id,
			start_date: this.dateFormat(this.cardsFilter.start_date_analytics), //this.cardsFilter.start_date_analytics.getDate(),
			end_date: this.dateFormat(this.cardsFilter.end_date_analytics),
			doctor_id :this.params.selectDoctor,	
			case_status:this.params.selectStatus
			
		};
		let date = this.getTime();
		this.apiService.sendAnalytics( analyticsData).subscribe(res => {
			let excelData: any = res.body;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = "hc_analytics_data__" + date + ".xlsx";
			FileSaver.saveAs(blob, fileName);
			this.params.selectDoctor='';
			this.params.selectStatus='';

		},(error)=>{
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		})
	}

}
