import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
declare var $: any;

@Component({
	selector: 'app-dashboard-inventory',
	templateUrl: './dashboard-inventory.component.html',
	styleUrls: ['./dashboard-inventory.component.scss', '../../assets/styles/dashboard-styles.scss']
})
export class DashboardInventoryComponent implements OnInit, AfterViewInit, OnDestroy {
	UserModuleAccess: any = "Full";
	constructor(private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService) {
		this.apiService.getUserAccess().subscribe((data: any) => {
			try {
				let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["PHARMA_dashboard"];
			} catch (error) { }
		}, (error: any) => {
			this.UserModuleAccess = "NoAccess";
		});
	}
	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => { });
	}
	ngAfterViewInit(): void {
		this.bindMap();
		this.PharmaTotalShipment();
	}
	ngOnDestroy(): void { }
	pagetitle = "Pharma";
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		{
			path: this.apiService.getDashboardURL("inventory"),
			name: this.pagetitle
		}
	];
	dateFilter = this.apiService.getDefaultDateRange(true);
	currentAccordion = "InvoiceModuleAlerts";
	markers: any[] = [];

	pharmaShipmentLoader = false;
	shipment = {
		count: 0
	}
	PharmaTotalShipment() {
		this.pharmaShipmentLoader = true;
		this.apiService.PharmaTotalShipment({}).subscribe((data: any) => {
			this.pharmaShipmentLoader = false;
			this.markers = data.data;
			this.markers = this.markers.filter((c:any)=>{
				try {
					return !c.name.includes("(0)");
				} catch (error) {
					return false;
				}
			})
			this.shipment.count = data.total_count;
			this.addMapPoint();
		}, (error) => {
			this.pharmaShipmentLoader = false;
		});
	}

	bindMap() {
		$('#worldmap').vectorMap({
			map: 'world_mill',
			scaleColors: ['#C8EEFF', '#0071A4'],
			normalizeFunction: 'polynomial',
			hoverOpacity: 0.7,
			hoverColor: false,
			markerStyle: {
				initial: {
					fill: '#F8E23B',
					stroke: '#383f47'
				}
			},
			backgroundColor: '#999999'
		});
	}

	addMapPoint() {
		this.removeAllMapPoint();
		var map = $('#worldmap').vectorMap('get', 'mapObject');
		var colorArr = ['#009933', '#FF8C00', "#FF0000", "#ff7d65", "#FFFF66", "#3f51b5", "#eba134", "#a2eb34", "#6beb34", "#00d998", "#0089d9", "#0045d9", "#3a26ed", "#9326ed", "#cc26ed", "#ed26c2", "#ed2683", "#ed265b", "#ed262d"];
		for (let i = 0; i < this.markers.length; i++) {
			this.markers[i].style = { fill: colorArr[i] ? colorArr[i] : "#ed262d", r: 5 };
		}
		map.addMarkers(this.markers, []);
	}

	removeAllMapPoint() {
		var map = $('#worldmap').vectorMap('get', 'mapObject');
		map.removeAllMarkers();
	}
	
	dateChanged(e: any) {
		this.dateFilter = e;
		this.accordionOpened(this.currentAccordion);
	}
	accordionOpened(id: any) {
		this.currentAccordion = id;
		if (id == 'InvoiceModuleAlerts') {
			this.getInvoiceModuleAlerts();
		} else if (id == 'TaskManagementAlerts') {
			this.getTaskAlerts();
		} else if (id == 'CompletedOrdersReport') {
			this.getCompletedOrdersReport();
		}
	}

	invoiceAlerts: any[] = [];
	loadingInvoiceAlerts = false;
	invoiceAlertsExcelLoader = false;
	invoiceExcelName = "Purchase Orders Report.xlsx";
	invoiceHeaderName = "Purchase Orders Report";
	getInvoiceModuleAlerts() {
		this.loadingInvoiceAlerts = true;
		this.apiService.simpleGetAPICall("InvoiceModuleAlerts", this.dateFilter).subscribe((data: any) => {
			this.loadingInvoiceAlerts = false;
			this.invoiceAlerts = data;
		}, (error) => {
			this.loadingInvoiceAlerts = false;
		});
	}
	getInvoiceModuleAlertsExcel(e: any) {
		e.stopPropagation();
		this.invoiceAlertsExcelLoader = true;
		this.apiService.simpleGetAPICallArrayBuffer("InvoiceModuleAlertsExcelDownload", this.dateFilter).subscribe(res => {
			let excelData: any = res.body;
			this.invoiceAlertsExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.invoiceExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.invoiceAlertsExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	taskAlerts: any[] = [];
	loadingTaskAlerts = false;
	taskAlertsExcelLoader = false;
	taskExcelName = "Task Management.xlsx";
	taskHeaderName = "Task Management";
	getTaskAlerts() {
		this.loadingTaskAlerts = true;
		this.apiService.simpleGetAPICall("TaskManagementAlerts", this.dateFilter).subscribe((data: any) => {
			this.loadingTaskAlerts = false;
			this.taskAlerts = data;
		}, (error) => {
			this.loadingTaskAlerts = false;
		});
	}
	getTaskkModuleAlertsExcel(e: any) {
		e.stopPropagation();
		this.taskAlertsExcelLoader = true;
		this.apiService.simpleGetAPICallArrayBuffer("TaskManagementAlertsExcelDownload", this.dateFilter).subscribe(res => {
			let excelData: any = res.body;
			this.taskAlertsExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.taskExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.taskAlertsExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	CompletedOrdersReport: any[] = [];
	CompletedOrdersReportLoader = false;
	CompletedOrdersReportExcelLoader = false;
	CompletedOrdersReportExcelName = "Completed Orders Report.xlsx";
	CompletedOrdersReportHeader = "Completed Orders Report";
	getCompletedOrdersReport() {
		this.CompletedOrdersReportLoader = true;
		this.apiService.simpleGetAPICall("CompletedOrdersReport", this.dateFilter).subscribe((data: any) => {
			this.CompletedOrdersReportLoader = false;
			this.CompletedOrdersReport = data;
		}, (error) => {
			this.CompletedOrdersReportLoader = false;
		});
	}
	getCompletedOrdersReportExcel(e: any) {
		e.stopPropagation();
		this.CompletedOrdersReportExcelLoader = true;
		this.apiService.simpleGetAPICallArrayBuffer("CompletedOrdersReportExcelDownload", this.dateFilter).subscribe(res => {
			let excelData: any = res.body;
			this.CompletedOrdersReportExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.CompletedOrdersReportExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.CompletedOrdersReportExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}
}
