<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div class="card" *ngIf="UserModuleAccess=='Full'">
	<div class="card-header bg-primary">
		{{pagetitle}}
	</div>
	<div class="card-body">
		<date-filter (changed)="dateChanged($event)"></date-filter>
		<mat-accordion [hideToggle]="false">
			<mat-expansion-panel [expanded]="true" (opened)="accordionOpened('CubeInvoicesReport')">
				<mat-expansion-panel-header class="bg-acc_style_1">
					<mat-panel-title>
						<b>{{CubeInvoicesReportHeader}}</b>
					</mat-panel-title>
					<mat-panel-description>
						<button class="btn btn-sm btn-light" matTooltip="Excel" [matTooltipPosition]="'below'"
							(click)="getCubeInvoicesReportExcel($event)">
							<fa-icon *ngIf="!CubeInvoicesReportExcelLoader"
								[icon]="['fas', 'cloud-download-alt']"></fa-icon>
							<fa-icon *ngIf="CubeInvoicesReportExcelLoader" [icon]="['fas', 'spinner']"
								animation="spin"></fa-icon>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-sm-12">
						<div class="row">
							<div class="col-sm-12">
								<div class="table-responsive" style="height: 450px;overflow-y: auto;">
									<table class="myTable table table-bordered table-hover mt-2" style="width: 100%;">
										<thead>
											<tr>
												<th style="width: 20% !important;">Invoice No</th>
												<th style="width: 20% !important;">Invoice Date</th>
												<th style="width: 40% !important;">Doctor</th>
												<th style="width: 20% !important;">Amount</th>
											</tr>
										</thead>
										<tbody *ngIf="CubeInvoicesReport?.length != 0">
											<tr class="table_td_style"
												*ngFor="let a of CubeInvoicesReport;let i = index;">
												<td>{{a.invoice_no}}</td>
												<td>{{a.invoice_date}}</td>
												<td>{{a.doctor}}</td>
												<td>{{a.amount}}</td>
											</tr>
										</tbody>
									</table>
									<p *ngIf="CubeInvoicesReport?.length == 0 && !CubeInvoicesReportLoader"
										class="text-center">
										No data found!
									</p>
									<p *ngIf="CubeInvoicesReportLoader" class="text-center text-bold">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
										Loading...
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel (opened)="accordionOpened('CandBillingReport')">
				<mat-expansion-panel-header class="bg-acc_style_2">
					<mat-panel-title>
						<b>{{CandBillingReportHeader}}</b>
					</mat-panel-title>
					<mat-panel-description>
						<button class="btn btn-sm btn-light" matTooltip="Excel" [matTooltipPosition]="'below'"
							(click)="getCandBillingReportExcel($event)">
							<fa-icon *ngIf="!CandBillingReportExcelLoader"
								[icon]="['fas', 'cloud-download-alt']"></fa-icon>
							<fa-icon *ngIf="CandBillingReportExcelLoader" [icon]="['fas', 'spinner']"
								animation="spin"></fa-icon>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-sm-12">
						<div class="row">
							<div class="col-sm-12">
								<div class="table-responsive" style="height: 450px;overflow-y: auto;">
									<table class="myTable table table-bordered table-hover mt-2" style="width: 100%;">
										<thead>
											<tr>
												<th style="width: 7% !important;">Bill No</th>
												<th style="width: 7% !important;">Company</th>
												<th style="width: 7% !important;">Candidate Name</th>
												<th style="width: 7% !important;">Rank</th>
												<th style="width: 7% !important;">Vessel</th>
												<th style="width: 7% !important;">Invoice Date</th>
												<th style="width: 9% !important;">Test</th>
												<th style="width: 7% !important;">Cost</th>
												<th style="width: 7% !important;">Total Amount</th>
												<th style="width: 7% !important;">Paid Amount</th>
												<th style="width: 7% !important;">Net Amount</th>
												<th style="width: 7% !important;">Invoice Sent</th>
												<th style="width: 7% !important;">Currency</th>
												<th style="width: 7% !important;">Doctor</th>
											</tr>
										</thead>
										<tbody *ngIf="CandBillingReport?.length != 0">
											<tr class="table_td_style"
												*ngFor="let a of CandBillingReport;let i = index;">
												<td>{{a.ref_no}}</td>
												<td>{{a.company}}</td>
												<td>{{a.cand_name}}</td>
												<td>{{a.rank}}</td>
												<td>{{a.vessel}}</td>
												<td>{{a.invoice_date}}</td>
												<td>{{a.test}}</td>
												<td>{{a.cost}}</td>
												<td>{{a.total_amount}}</td>
												<td>{{a.paid_amount}}</td>
												<td>{{a.net_amount}}</td>
												<td>{{a.invoice_sent}}</td>
												<td>{{a.currency_type}}</td>
												<td>{{a.doctor}}</td>
											</tr>
										</tbody>
									</table>
									<p *ngIf="CandBillingReport?.length == 0 && !CandBillingReportLoader"
										class="text-center">
										No data found!
									</p>
									<p *ngIf="CandBillingReportLoader" class="text-center text-bold">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
										Loading...
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>