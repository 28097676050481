import { Component, EventEmitter, forwardRef, inject, Input, Output } from '@angular/core';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { mobilePattern, emailPattern } from '../../shared/constants';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'phone-email-input',
	templateUrl: './phone-input.component.html',
	styleUrl: './phone-input.component.css',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PhoneInputComponent),
			multi: true,
		},
	]
})
export class PhoneInputComponent {
	@Input() label: string = "";
	@Input() id: string = "";
	@Input() required: boolean = false;
	@Input() type: string = "phone";
	@Input() hasError: boolean = false;
	@Input() errors: any = {};
	@Input() customError: any = null;
	values: any[] = [];
	onChange: any = () => { };
	onTouched: any = () => { };

	constructor(private toastr: ToastrService) { }

	writeValue(value: any): void {
		this.values = value || [];
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	removeVal(val: string) {
		const index = this.values.indexOf(val);
		if (index >= 0) {
			this.values.splice(index, 1);
		}
	}

	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();
		let pattern:RegExp;
		if(this.type == 'phone'){
			pattern = mobilePattern;
		}else{
			pattern = emailPattern;
		}
		if (pattern.test(value)) {
			this.values.push(value);
			event.chipInput!.clear();
		} else {
			this.toastr.error("Invalid value.", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		}
	}
}
