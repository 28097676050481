<mat-form-field class="example-form-field">
	<mat-label>{{type=="phone"?"Phone Numbers":"Email Ids"}}</mat-label>
	<mat-chip-grid #chipGrid [id]="id" [(ngModel)]="values"
		(ngModelChange)="onChange($event)" [required]="required">
		@for (val of values; track val) {
		<mat-chip-row (removed)="removeVal(val)">
			{{val}}
			<button matChipRemove>
				<fa-icon [icon]="['fas', 'times']"></fa-icon>
			</button>
		</mat-chip-row>
		}
	</mat-chip-grid>
	<input placeholder="Add {{type=='phone'?'phone no.':'email id'}} and press enter" [matChipInputFor]="chipGrid" (matChipInputTokenEnd)="add($event)" />
</mat-form-field>
<p *ngIf="hasError" class="text-danger">
	<span *ngIf="errors?.required">
		Required
	</span>
	<span *ngIf="errors?.incorrect">
		{{customError}}
	</span>
</p>