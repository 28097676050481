<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div class="card" *ngIf="UserModuleAccess=='Full'">
	<div class="card-body">
		<h2 class="card-title">
			{{pagetitle}}
			<a class="btn btn-sm btn-outline-success float-end" [routerLink]="['/app/create-vessel']">
				<fa-icon [icon]="['fas', 'plus']"></fa-icon>
				Create Vessel
			</a>
		</h2>
		<div class="row">
			<div class="col-md-12">
				<div class="table-responsive">
					<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered table-hover"
						style="width: 100%;">
						<thead>
							<tr class="table_head">
								<th style="width: 19% !important;">Company</th>
								<th style="width: 18% !important;">Fleet</th>
								<th style="width: 18% !important;">Vessel</th>
								<th style="width: 15% !important;">Email</th>
								<th style="width: 5% !important;" class="text-center">CLIP</th>
								<th style="width: 5% !important;" class="text-center">HC</th>
								<th style="width: 5% !important;" class="text-center">MHS</th>
								<th style="width: 5% !important;" class="text-center">Pharma</th>
								<th style="width: 10% !important;">Action</th>
							</tr>
						</thead>
						<tbody *ngIf="dataList?.length != 0">
							<tr *ngFor="let v of dataList;let i = index;">
								<td>{{ v.company?v.company.name:"-" }}</td>
								<td>{{ v.fleet?v.fleet.name:"-" }}</td>
								<td>{{ v.name }}</td>
								<td>{{ v.email }}</td>
								<td class="text-center">
									<button *ngIf="v.clip_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from CLIP" id="btnDeleteCLIP{{v.id1}}"
										(click)="deleteConfirmCLIP(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderCLIP != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderCLIP == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.clip_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in CLIP" id="btnCreateCLIP{{v.id1}}"
										(click)="createConfirmCLIP(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderCLIP != i" [icon]="['fas', 'flag']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderCLIP == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td class="text-center">
									<button *ngIf="v.hc_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from HC" id="btnDeleteHC{{v.id1}}"
										(click)="deleteConfirmHC(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderHC != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderHC == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.hc_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in HC" id="btnCreateHC{{v.id1}}"
										(click)="createConfirmHC(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderHC != i" [icon]="['fas', 'flag']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderHC == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td class="text-center">
									<button *ngIf="v.mhs_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from MHS" id="btnDeleteMHS{{v.id1}}"
										(click)="deleteConfirmMHS(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderMHS != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderMHS == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.mhs_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in MHS" id="btnCreateMHS{{v.id1}}"
										(click)="createConfirmMHS(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderMHS != i" [icon]="['fas', 'flag']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderMHS == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td class="text-center">
									<button *ngIf="v.pharma_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from Pharma" id="btnDeletePharma{{v.id1}}"
										(click)="deleteConfirmPharma(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderPharma != i"
											[icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderPharma == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.pharma_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in Pharma" id="btnCreatePharma{{v.id1}}"
										(click)="createConfirmPharma(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderPharma != i" [icon]="['fas', 'flag']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderPharma == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td>
									<a class="btn btn-sm btn-outline-primary" matTooltip="Edit" id="btnEdit{{v.id1}}"
										[routerLink]="['/app/edit-vessel',v.id1]">
										<fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
									</a>
									<button class="btn btn-sm btn-outline-danger ms-1" matTooltip="Delete"
										id="btnDelete{{v.id1}}" (click)="deleteConfirm(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoader != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoader == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
							</tr>
						</tbody>
						<tbody *ngIf="dataList?.length == 0">
							<tr>
								<td colspan="9" class="text-center">No data found!</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>