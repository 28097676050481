import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-dashboard-mdm',
	templateUrl: './dashboard-mdm.component.html',
	styleUrls: ['./dashboard-mdm.component.css', '../../assets/styles/dashboard-styles.scss']
})
export class DashboardMdmComponent implements OnInit {
	UserModuleAccess: any = "Full";
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		{
			path: '/app/dashboard-mdm',
			name: "MDM"
		}
	];
	constructor(private apiService: ApiService, private activeRoute: ActivatedRoute) {
		/* this.apiService.getUserAccess().subscribe((data: any) => {
			try {
				let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["CDM_dashboard"];
			} catch (error) { }
		}, (error: any) => {
			this.UserModuleAccess = "NoAccess";
		}); */
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => { });
	}
}
