<div class="form-floating">
	<input *ngIf="type != 'textarea'" [type]="type" class="form-control" [ngClass]="{'is-invalid':hasError}" [id]="id"
		[(ngModel)]="value" (ngModelChange)="onChange($event)" (blur)="onTouched()" placeholder="">
	<textarea *ngIf="type == 'textarea'" class="form-control" [ngClass]="{'is-invalid':hasError}" [id]="id"
		[(ngModel)]="value" (ngModelChange)="onChange($event)" (blur)="onTouched()" placeholder=""></textarea>
	<label for="name">{{label}}{{required?"*":""}}</label>
</div>
<p *ngIf="hasError" class="text-danger">
	<span *ngIf="errors?.required">
		Required
	</span>
	<span *ngIf="errors?.pattern">
		Invalid
	</span>
	<span *ngIf="errors?.incorrect">
		{{customError}}
	</span>
</p>