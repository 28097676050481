<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div class="card" *ngIf="UserModuleAccess=='Full'">
	<div class="card-header bg-primary">
		{{pagetitle}}
	</div>
	<div class="card-body">
		<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="saveData()" #modform="ngForm">
			<div class="row mb-3">
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<custom-input formControlName="inv_no" [id]="'inv_no'" [label]="'Invoice No.'"
						[required]="f.inv_no.hasValidator(requiredValidator)" [hasError]="showError('inv_no')"
						[errors]="f.inv_no.errors" [customError]="customError?.inv_no"></custom-input>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<mat-form-field>
						<mat-label>Invoice Date(MM/DD/YYYY)</mat-label>
						<input matInput [matDatepicker]="invoice_date_picker" formControlName="inv_date">
						<mat-datepicker-toggle matIconSuffix [for]="invoice_date_picker"></mat-datepicker-toggle>
						<mat-datepicker #invoice_date_picker></mat-datepicker>
					</mat-form-field>
					<p *ngIf="showError('inv_date')" class="text-danger">
						<span *ngIf="f.inv_date.errors?.required">
							Required
						</span>
						<span *ngIf="f.inv_date.errors?.incorrect">
							{{customError.inv_date}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<custom-input formControlName="amount" [id]="'amount'" [label]="'Amount'"
						[required]="f.amount.hasValidator(requiredValidator)" [hasError]="showError('amount')"
						[errors]="f.amount.errors" [customError]="customError?.amount"></custom-input>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
					<mat-form-field appearance="outline">
						<mat-label>Currency</mat-label>
						<mat-select formControlName="currency">
							<mat-option [value]="null">--Select--</mat-option>
							@for (curr of currencyList; track curr) {
							<mat-option [value]="curr.name">{{curr.name}}</mat-option>
							}
						</mat-select>
					</mat-form-field>
					<p *ngIf="showError('currency')" class="text-danger">
						<span *ngIf="f.currency.errors?.required">
							Required
						</span>
						<span *ngIf="f.currency.errors?.incorrect">
							{{customError.currency}}
						</span>
					</p>
				</div>
				<div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
					<br />
					<ng-toggle formControlName="is_paid" [color]="{ unchecked: '#bfcbd9', checked: '#2494f2' }"
						[fontColor]="{checked: '#ffffff',unchecked: '#000000'}"
						[switchColor]="{checked: '#198754',unchecked: '#dc3545'}"
						[labels]="{'unchecked': 'Unpaid','checked': 'Paid'}" [width]="90"></ng-toggle>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<ng-select appendTo="body" [items]="headerList" bindLabel="header_name" bindValue="id" [clearable]="true"
						[multiple]="false" id="headers_master_id" formControlName="headers_master_id"
						placeholder="Header*" addTagText="Create new:" [addTag]="addNewHeader.bind(this)">
					</ng-select>
					<p *ngIf="showError('headers_master_id')" class="text-danger">
						<span *ngIf="f.headers_master_id.errors?.required">
							Required
						</span>
						<span *ngIf="f.headers_master_id.errors?.incorrect">
							{{customError.headers_master_id}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<ng-select appendTo="body" [items]="servicesList" bindLabel="service_name" bindValue="id" [clearable]="true"
						[multiple]="false" id="services_master_id" formControlName="services_master_id"
						placeholder="Service*" addTagText="Create new:" [addTag]="addNewService.bind(this)">
					</ng-select>
					<p *ngIf="showError('services_master_id')" class="text-danger">
						<span *ngIf="f.services_master_id.errors?.required">
							Required
						</span>
						<span *ngIf="f.services_master_id.errors?.incorrect">
							{{customError.services_master_id}}
						</span>
					</p>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col text-center">
					<button type="submit" [disabled]="showLoader || !modform.dirty"
						class="btn btn-sm btn-outline-success">
						<span class="me-1">
							<fa-icon *ngIf="showLoader" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
							<fa-icon *ngIf="!showLoader" [icon]="['fas', 'save']"></fa-icon>
						</span>
						<span>Save</span>
					</button>
					<a class="btn btn-sm btn-outline-dark ms-1" [routerLink]="['/app/overhead']">Cancel</a>
				</div>
			</div>
		</form>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>