<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div class="card" *ngIf="UserModuleAccess=='Full'">
	<div class="card-body">
		<h2 class="card-title">
			MDM Users
			<a class="btn btn-sm btn-outline-success float-end" [routerLink]="['/app/mdm-create-user']">
				<fa-icon [icon]="['fas', 'plus']"></fa-icon>
				Create User
			</a>
		</h2>
		<div class="row">
			<div class="col-md-12">
				<div class="table-responsive">
					<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered table-hover"
						style="width: 100%;">
						<thead>
							<tr class="table_head">
								<th style="width: 30% !important;">Name</th>
								<th style="width: 20% !important;">Email</th>
								<th style="width: 20% !important;">Contact</th>
								<th style="width: 5% !important;" class="text-center">CLIP</th>
								<th style="width: 5% !important;" class="text-center">HC</th>
								<th style="width: 5% !important;" class="text-center">MHS</th>
								<th style="width: 5% !important;" class="text-center">Pharma</th>
								<th style="width: 10% !important;">Action</th>
							</tr>
						</thead>
						<tbody *ngIf="dataList?.length != 0">
							<tr *ngFor="let v of dataList;let i = index;">
								<td>
									<fa-icon [icon]="['fas', v.gender=='Female'?'female':'male']"></fa-icon>
									{{ v.first_name }} {{ v.last_name }}
								</td>
								<td>{{ v.email }}</td>
								<td>{{ v.country_code }} {{ v.contact_no }}</td>
								<td class="text-center">
									<button *ngIf="v.clip_user_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from CLIP" id="btnDeleteCLIP{{v.id1}}"
										(click)="deleteConfirmCLIP(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderCLIP != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderCLIP == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.clip_user_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in CLIP" id="btnCreateCLIP{{v.id1}}"
										(click)="createConfirmCLIP(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderCLIP != i"
											[icon]="['fas', 'user-plus']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderCLIP == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td class="text-center">
									<button *ngIf="v.hc_user_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from HC" id="btnDeleteHC{{v.id1}}"
										(click)="deleteConfirmHC(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderHC != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderHC == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.hc_user_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in HC" id="btnCreateHC{{v.id1}}"
										(click)="createConfirmHC(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderHC != i"
											[icon]="['fas', 'user-plus']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderHC == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td class="text-center">
									<button *ngIf="v.mhs_user_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from MHS" id="btnDeleteMHS{{v.id1}}"
										(click)="deleteConfirmMHS(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderMHS != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderMHS == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.mhs_user_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in MHS" id="btnCreateMHS{{v.id1}}"
										(click)="createConfirmMHS(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderMHS != i"
											[icon]="['fas', 'user-plus']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderMHS == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td class="text-center">
									<button *ngIf="v.pharma_user_id" class="btn btn-sm btn-outline-danger"
										matTooltip="Delete from Pharma" id="btnDeletePharma{{v.id1}}"
										(click)="deleteConfirmPharma(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoaderPharma != i"
											[icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoaderPharma == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button *ngIf="!v.pharma_user_id" class="btn btn-sm btn-outline-success"
										matTooltip="Create in Pharma" id="btnCreatePharma{{v.id1}}"
										(click)="createConfirmPharma(v.id1,i)">
										<fa-icon *ngIf="showCreateLoaderPharma != i"
											[icon]="['fas', 'user-plus']"></fa-icon>
										<fa-icon *ngIf="showCreateLoaderPharma == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
								<td>
									<a class="btn btn-sm btn-outline-primary" matTooltip="Edit" id="btnEdit{{v.id1}}"
										[routerLink]="['/app/mdm-edit-user',v.id1]">
										<fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
									</a>
									<button class="btn btn-sm btn-outline-danger ms-1" matTooltip="Delete"
										id="btnDelete{{v.id1}}" (click)="deleteConfirm(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoader != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoader == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button class="btn btn-sm btn-outline-info ms-1" matTooltip="Change Password"
										id="btnResetPass{{v.id1}}" (click)="resetPasswordModal(v.id1)">
										<fa-icon [icon]="['fas', 'lock']"></fa-icon>
									</button>
									<!-- <button *ngIf="v.enable2fa" class="btn btn-sm btn-outline-success ms-1"
										matTooltip="Reset 2 Factor Authentication" id="btnReset2FA{{v.id1}}"
										(click)="reset2FA(v.id1)">
										<fa-icon *ngIf="showReset2FALoader != v.id1" [icon]="['fas', 'key']"></fa-icon>
										<fa-icon *ngIf="showReset2FALoader == v.id1" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button> -->
								</td>
							</tr>
						</tbody>
						<tbody *ngIf="dataList?.length == 0">
							<tr>
								<td colspan="8" class="text-center">No data found!</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>
<div class="modal fade" id="changeMDMUserPasswordModal" role="dialog">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<form class="form-horizontal" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()"
				#passform="ngForm">
				<div class="modal-header">
					<h4 class="modal-title" id="modal-basic-title">Change Password</h4>
					<button type="button" class="btn-close" aria-label="Close" (click)="clearResetPassword()"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-sm-4">
							<mat-form-field>
								<mat-label>New Password</mat-label>
								<input type="password" matInput id="password" formControlName="password"
									name="password">
								<mat-error
									*ngIf="f.password.invalid && (passform.submitted || f.password.touched || f.password.dirty)">
									<span *ngIf="f.password.errors?.required">
										Required
									</span>
								</mat-error>
							</mat-form-field>
						</div>
						<div class="col-sm-4">
							<mat-form-field>
								<mat-label>Confirm Password</mat-label>
								<input type="password" matInput id="confirmPassword" formControlName="confirmPassword"
									name="confirmPassword">
								<mat-error
									*ngIf="f.confirmPassword.invalid && (passform.submitted || f.confirmPassword.touched || f.confirmPassword.dirty)">
									<span *ngIf="f.confirmPassword.errors?.required">
										Required
									</span>
								</mat-error>
							</mat-form-field>
							<mat-error
								*ngIf="resetPasswordForm.errors && (passform.submitted || f.confirmPassword.touched)">
								<p *ngIf="resetPasswordForm.errors?.passwordNotMatch">
									Password and Confirm Password not matched!
									<fa-icon [icon]="['fas', 'times']"></fa-icon>
								</p>
							</mat-error>
						</div>
						<div class="col-sm-4">
							<div *ngIf="f.password.invalid && (passform.submitted || f.password.touched)" type="danger">
								<p [ngClass]="f.password.errors?.minlengtherror?'text-danger':'text-success'">
									Must be atleast 8 characters long.
								</p>
								<p [ngClass]="f.password.errors?.specialcharerror?'text-danger':'text-success'">
									Must have atleast 1 special character.
								</p>
								<p [ngClass]="f.password.errors?.uppercaseerror?'text-danger':'text-success'">
									Must have atleast 1 upper case character.
								</p>
								<p [ngClass]="f.password.errors?.lowercaseerror?'text-danger':'text-success'">
									Must have atleast 1 lower case character.
								</p>
								<p [ngClass]="f.password.errors?.digiterror?'text-danger':'text-success'">
									Must have atleast 1 digit.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer" style="text-align: left;">
					<button type="submit" class="btn btn-sm btn-outline-primary">
						<fa-icon *ngIf="resetPasswordLoader" [icon]="['fas', 'spinner']" [animation]="'spin'"></fa-icon>
						Reset
					</button>
					<button type="button" class="btn btn-sm btn-outline-warning" data-dismiss="modal"
						(click)="clearResetPassword();">Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</div>