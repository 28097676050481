<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div class="card" *ngIf="UserModuleAccess=='Full'">
	<div class="card-header bg-primary">
		{{pagetitle}}
	</div>
	<div class="card-body">
		<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="saveData()" #modform="ngForm">
			<div class="row mb-3">
				<div class="col">
					<ng-select appendTo="body" [items]="companyList" bindLabel="name" bindValue="id" [clearable]="true"
						[multiple]="false" id="company_master" formControlName="company_master" name="company_master"
						placeholder="Company*">
					</ng-select>
					<p *ngIf="showError('company_master')" class="text-danger">
						<span *ngIf="f.company_master.errors?.required">
							Required
						</span>
						<span *ngIf="f.company_master.errors?.incorrect">
							{{customError.company_master}}
						</span>
					</p>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('name')}" id="name"
							formControlName="name" placeholder="">
						<label for="name">Fleet Name*</label>
					</div>
					<p *ngIf="showError('name')" class="text-danger">
						<span *ngIf="f.name.errors?.required">
							Required
						</span>
						<span *ngIf="f.name.errors?.incorrect">
							{{customError.name}}
						</span>
					</p>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('EmailId')}" id="EmailId"
							formControlName="EmailId" placeholder="">
						<label for="EmailId">Email Id*</label>
					</div>
					<p *ngIf="showError('EmailId')" class="text-danger">
						<span *ngIf="f.EmailId.errors?.required">
							Required
						</span>
						<span *ngIf="f.EmailId.errors?.pattern">
							Invalid
						</span>
						<span *ngIf="f.EmailId.errors?.incorrect">
							{{customError.EmailId}}
						</span>
					</p>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col">
					<div class="w-50 float-start">
						<ng-select appendTo="body" [items]="countries" bindLabel="display" bindValue="dial_code"
							[clearable]="true" [multiple]="false" id="country_code" formControlName="country_code"
							name="country_code" placeholder="Country Code*">
						</ng-select>
						<p *ngIf="showError('country_code')" class="text-danger">
							<span *ngIf="f.country_code.errors?.required">
								Required
							</span>
							<span *ngIf="f.country_code.errors?.incorrect">
								{{customError.country_code}}
							</span>
						</p>
					</div>
					<div class="w-50 float-start">
						<div class="form-floating">
							<input class="form-control" [ngClass]="{'is-invalid':showError('contact_no')}" id="contact_no"
								formControlName="contact_no" placeholder="">
							<label for="contact_no">Contact No.*</label>
						</div>
						<p *ngIf="showError('contact_no')" class="text-danger">
							<span *ngIf="f.contact_no.errors?.required">
								Required
							</span>
							<span *ngIf="f.contact_no.errors?.pattern">
								Invalid
							</span>
							<span *ngIf="f.contact_no.errors?.incorrect">
								{{customError.contact_no}}
							</span>
						</p>
					</div>
				</div>
				<div class="col">
					<ng-select appendTo="body" [items]="pocList" bindLabel="full_name" bindValue="id" [clearable]="true"
						[multiple]="true" id="contact_person" formControlName="contact_person" name="contact_person"
						placeholder="Contact Persons*">
					</ng-select>
					<p *ngIf="showError('contact_person')" class="text-danger">
						<span *ngIf="f.contact_person.errors?.required">
							Required
						</span>
						<span *ngIf="f.contact_person.errors?.incorrect">
							{{customError.contact_person}}
						</span>
					</p>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('address')}" id="address"
							formControlName="address" placeholder="">
						<label for="address">Address*</label>
					</div>
					<p *ngIf="showError('address')" class="text-danger">
						<span *ngIf="f.address.errors?.required">
							Required
						</span>
						<span *ngIf="f.address.errors?.incorrect">
							{{customError.address}}
						</span>
					</p>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col text-center">
					<button type="submit" [disabled]="showLoader || !modform.dirty"
						class="btn btn-sm btn-outline-success">
						<span class="me-1">
							<fa-icon *ngIf="showLoader" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
							<fa-icon *ngIf="!showLoader" [icon]="['fas', 'save']"></fa-icon>
						</span>
						<span>Save</span>
					</button>
					<a class="btn btn-sm btn-outline-dark ms-1" [routerLink]="['/app/fleet']">Cancel</a>
				</div>
			</div>
		</form>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>