import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-dashboard-clip',
	templateUrl: './dashboard-clip.component.html',
	styleUrls: ['./dashboard-clip.component.scss', '../../assets/styles/dashboard-styles.scss']
})
export class DashboardClipComponent implements OnInit, AfterViewInit, OnDestroy {
	UserModuleAccess: any = "Full";
	constructor(private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService) {
		this.apiService.getUserAccess().subscribe((data: any) => {
			try {
				let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["CLIP_dashboard"];
			} catch (error) { }
		}, (error: any) => {
			this.UserModuleAccess = "NoAccess";
		});
	}
	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => { });
	}
	ngAfterViewInit(): void { }
	ngOnDestroy(): void { }
	pagetitle = "CLIP";
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		{
			path: this.apiService.getDashboardURL("clip"),
			name: this.pagetitle
		}
	];
	dateFilter = this.apiService.getDefaultDateRange(true);
	currentAccordion = "InvoiceModuleAlerts";
	dateChanged(e: any) {
		this.dateFilter = e;
		this.accordionOpened(this.currentAccordion);
	}
	accordionOpened(id: any) {
		this.currentAccordion = id;
		if (id == 'CubeInvoicesReport') {
			this.getCubeInvoicesReport();
		} else if (id == 'CandBillingReport') {
			this.getCandBillingReport();
		}
	}

	CubeInvoicesReport: any[] = [];
	CubeInvoicesReportLoader = false;
	CubeInvoicesReportExcelLoader = false;
	CubeInvoicesReportExcelName = "Subscribe Report.xlsx";
	CubeInvoicesReportHeader = "Subscribe Report";
	getCubeInvoicesReport() {
		this.CubeInvoicesReportLoader = true;
		this.apiService.simpleGetAPICall("CubeInvoicesReport", this.dateFilter).subscribe((data: any) => {
			this.CubeInvoicesReportLoader = false;
			this.CubeInvoicesReport = data;
		}, (error) => {
			this.CubeInvoicesReportLoader = false;
		});
	}
	getCubeInvoicesReportExcel(e: any) {
		e.stopPropagation();
		this.CubeInvoicesReportExcelLoader = true;
		this.apiService.simpleGetAPICallArrayBuffer("CubeInvoicesReportExcelDownload", this.dateFilter).subscribe(res => {
			let excelData: any = res.body;
			this.CubeInvoicesReportExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.CubeInvoicesReportExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.CubeInvoicesReportExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	CandBillingReport: any[] = [];
	CandBillingReportLoader = false;
	CandBillingReportExcelLoader = false;
	CandBillingReportExcelName = "Shorecare Report.xlsx";
	CandBillingReportHeader = "Shorecare Report";
	getCandBillingReport() {
		this.CandBillingReportLoader = true;
		this.apiService.simpleGetAPICall("CandBillingReport", this.dateFilter).subscribe((data: any) => {
			this.CandBillingReportLoader = false;
			this.CandBillingReport = data;
		}, (error) => {
			this.CandBillingReportLoader = false;
		});
	}
	getCandBillingReportExcel(e: any) {
		e.stopPropagation();
		this.CandBillingReportExcelLoader = true;
		this.apiService.simpleGetAPICallArrayBuffer("CandBillingReportExcelDownload", this.dateFilter).subscribe(res => {
			let excelData: any = res.body;
			this.CandBillingReportExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.CandBillingReportExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.CandBillingReportExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}
}
