import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
@Component({
	selector: 'custom-input',
	templateUrl: './custom-input.component.html',
	styleUrl: './custom-input.component.css',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CustomInputComponent),
			multi: true,
		},
	],
})
export class CustomInputComponent {
	@Input() label: string = "";
	@Input() id: string = "";
	@Input() required: boolean = false;
	@Input() type: string = "text";
	@Input() hasError: boolean = false;
	@Input() errors: any = {};
	@Input() customError: any = null;

	value: any = null;
	onChange: any = () => { };
	onTouched: any = () => { };

	writeValue(value: string): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
}
