<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="dateChanged()" #modform="ngForm">
	<div class="row my-2">
		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
			<mat-card>
				<mat-card-content>
					<mat-form-field class="me-2">
						<mat-label>Start Date</mat-label>
						<input matInput [matDatepicker]="start_date_picker_template" formControlName="start_date">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="start_date_picker_template"></mat-datepicker-toggle>
						<mat-datepicker #start_date_picker_template></mat-datepicker>
					</mat-form-field>
					<mat-form-field class="me-2">
						<mat-label>End Date</mat-label>
						<input matInput [matDatepicker]="end_date_picker_template" formControlName="end_date">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="end_date_picker_template"></mat-datepicker-toggle>
						<mat-datepicker #end_date_picker_template></mat-datepicker>
					</mat-form-field>
					<button type="submit" class="btn btn-primary">
						<fa-icon [icon]="['fas', 'filter']"></fa-icon>
						Apply
					</button>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</form>