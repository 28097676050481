<div [ngStyle]="{'background-image':'url(../assets/img/bg_img.jpg)','height':ScreenHeight+'px','background-repeat': 'round'}"
	class="mb-2">
	<div *ngIf="loadershow" class="loader text-center">
		<img src="../assets/img/cc_loader.gif" />
	</div>
	<div class="container container-sm">
		<div class="row">
			<div class="col"></div>
			<div style="background-color: #bfbfbf;" class="col-6 rounded my-5">
				<div class="row">
					<div class="col text-center my-4">
						<img src="../assets/img/logo_new.png" alt="Image" width="130px" />
					</div>
				</div>
				<div class="row" *ngIf="step == 1">
					<div class="col">
						<form role="form" (ngSubmit)="submitForm(user)">
							<div class="row mt-2">
								<div class="col">
									<div class="input-group mb-3">
										<span class="input-group-text" id="basic-addon1"><fa-icon
												[icon]="['fas', 'user']"></fa-icon></span>
										<input type="text" class="form-control" placeholder="Username"
											aria-label="Username" aria-describedby="basic-addon1"
											[(ngModel)]="user.username" [ngModelOptions]="{standalone: true}">
									</div>
								</div>
							</div>
							<div class="row mt-2">
								<div class="col">
									<div class="input-group mb-3">
										<span class="input-group-text" id="basic-addon1"><fa-icon
												[icon]="['fas', 'lock']"></fa-icon></span>
										<input type="password" class="form-control" placeholder="Password"
											aria-label="Password" aria-describedby="basic-addon1"
											[(ngModel)]="user.password" [ngModelOptions]="{standalone: true}">
									</div>
								</div>

							</div>
							<div class="row mt-2">
								<div class="col text-center">
									<button id="btnSubmit" type="submit" class="btn btn-outline-success w-100">Login</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="row px-1" *ngIf="step == 2">
					<div class="col" *ngIf="qrStep == 1">
						<div class="row">
							<div class="col-9">
								<h3>Set Up Google Authenticator App</h3>
								<h4>
									<div class="my-circle">1</div>Download an App
								</h4>
								<p class="px-3">
									Download the Google Authenticator App from&nbsp;
									<a href="https://play.google.com" target="_blank">Google Play Store</a>
									&nbsp;or&nbsp;
									<a href="https://apps.apple.com" target="_blank">App Store.</a>
								</p>
							</div>
							<div class="col text-center">
								<div class="app-icon-container">
									<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
										target="_blank" class="mt-4"><img src="../assets/img/get_it_on_google_play.png"
											class="w-75 h-auto" /></a>
									<a href="https://apps.apple.com/us/app/google-authenticator/id388497605"
										target="_blank"><img src="../assets/img/download_on_app_store.png"
											class="mt-2 w-75  h-auto" /></a>
								</div>
								<button id="btnIHaveApp" type="button" class="btn btn-outline-success my-2 w-100"
									(click)="next()">I Have An
									App</button>
							</div>
						</div>
					</div>
					<div class="col" *ngIf="qrStep == 2">
						<form role="form" (ngSubmit)="verifyOTP()">
							<div class="row" *ngIf="showQR">

								<div class="col-9">
									<h3>Set Up Google Authenticator App</h3>
									<h4>
										<div class="my-circle">2</div>Add Your Account
									</h4>
									<p style="padding-left: 30px;">
										Scan the QR code to get the code you will need to complete the verification
										in
										step 3 and to verify future logins.
									</p>
									<h4>
										<div class="my-circle">3</div>Verify Your Account
									</h4>
									<p style="padding-left: 30px;">
										Enter the 6 digit code provided from your authenticator app.
									</p>
								</div>
								<div class="col text-center">
									<img src="{{qrToShow}}" alt="QR Code" class="w-100 h-auto" />
								</div>
							</div>
							<div class="row my-2">
								<div class="col">
									<div class="input-group mb-3">
										<span class="input-group-text" id="basic-addon1">Code</span>
										<input type="text" class="form-control" placeholder="Enter Code"
											aria-label="otp" aria-describedby="basic-addon1" [(ngModel)]="auth.otp"
											[ngModelOptions]="{standalone: true}" required>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<button id="btnSubmit1" type="submit" class="btn btn-outline-success w-100">Verify</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="row my-2">
					<div class="col text-center text-black" style="font-size: smaller;">
						&nbsp;&copy; {{year}}&nbsp;-&nbsp;
						<a href="http://3cubeservices.com/" target="_blank" class="text-black">3 Cube Services</a>
					</div>
				</div>
				<div class="row my-2">
					<div class="col text-center">
						<a href="mailto:info@3cubeservices.com" style="color:#000;">info&#64;3cubeservices.com </a>
					</div>
				</div>
				<div class="row my-1" *ngIf="loginErrorshow">
					<div class="col">
						<div class="alert alert-danger text-center" role="alert">
							<label>{{loginFormErr}}</label>
						</div>
					</div>
				</div>
			</div>
			<div class="col"></div>
		</div>
	</div>
</div>