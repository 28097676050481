import { FormControl } from "@angular/forms";

export const emailPattern = /^[a-zA-Z0-9]+[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z.]{2,6}$/;
export const mobilePattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export function formatAmount(amount:any){
	if(!isNaN(amount) && amount != null && amount !=undefined){
		return amount.toLocaleString();
	}else{
		return amount;
	}
}

export function password_validation(control: FormControl) {
	let password: any = control.value;
	if (password == null || password == undefined) {
		password = "";
	}
	let errObj: any = {};
	if (password.length < 8) {
		errObj.minlengtherror = true;
	}
	const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
	if (!specialChars.test(password)) {
		errObj.specialcharerror = true;
	}
	const upper = /[A-Z]/;
	const lower = /[a-z]/;
	const digit = /\d/;
	if (!upper.test(password)) {
		errObj.uppercaseerror = true;
	}
	if (!lower.test(password)) {
		errObj.lowercaseerror = true;
	}
	if (!digit.test(password)) {
		errObj.digiterror = true;
	}
	return errObj ? errObj : null;
}

export function noWhitespaceInRequiredValidator(control: FormControl) {
	let val: any = control.value;
	if (val == null) {
		return { 'required': true };
	}
	let type = typeof val;
	if (type == "number" || type == "bigint" || type == "object") {
		return null;
	}
	const isWhitespace = (control.value || '').trim().length === 0;
	const isValid = !isWhitespace;
	return isValid ? null : { 'required': true };
}