import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'date-filter',
	templateUrl: './date-filter.component.html',
	styleUrl: './date-filter.component.css'
})
export class DateFilterComponent {
	moduleForm!: FormGroup;
	@Output() changed = new EventEmitter<any>();

	constructor(private formBuilder: FormBuilder, private apiService: ApiService, private toastr: ToastrService) {
		this.moduleForm = this.formBuilder.group({
			start_date: [this.apiService.getDefaultDateRange3Months(false).start_date, [Validators.required]],
			end_date: [this.apiService.getDefaultDateRange3Months(false).end_date, [Validators.required]]
		});
		this.dateChanged();
	}

	get f() { return this.moduleForm.controls; }

	dateChanged() {
		if (this.f.start_date.invalid) {
			this.toastr.warning("Please select start date", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
			return;
		}
		if (this.f.end_date.invalid) {
			this.toastr.warning("Please select end date", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
			return;
		}
		let dateFilter = {
			start_date: this.apiService.getDateStringFromDate(this.f.start_date.value),
			end_date: this.apiService.getDateStringFromDate(this.f.end_date.value)
		};
		this.changed.emit(dateFilter);
	}
}
